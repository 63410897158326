import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import moment from 'moment';

function Cust_Holliday_Calendar() {
  const currentYear = moment().year();
  const [holidaycal, Setholidaycal] = useState({ lve_hld_calndr: [] });
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    const shw_hlod_res = async () => {
      try {
        const holiday_dt = await services.get_lev_holiday_cal();
        if (holiday_dt) {
          Setholidaycal(holiday_dt);

          // Filter holidays for the current year and set to filteredHolidays
          const currentYearHolidays = holiday_dt.lve_hld_calndr.filter(leave => {
            const holidayDate = moment(leave.HOLIDAY_DATE);
            return holidayDate.year() === currentYear;
          });
          setFilteredHolidays(currentYearHolidays); // Initialize filtered holidays to current year
        } else {
          console.error("Unexpected data format:", holiday_dt);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    shw_hlod_res();
  }, [currentYear]); // Add currentYear as a dependency

  const handleFilter = () => {
    const filtered = holidaycal.lve_hld_calndr.filter(leave => {
      const holidayDate = moment(leave.HOLIDAY_DATE);
      return holidayDate.year() === selectedYear; // Only filter by year
    });
    setFilteredHolidays(filtered);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
  };
  console.log("holidaycal", holidaycal)
  return (
    <div className='wrapper_form'>
      <Grid item xs={12} className="grid-item">
        <div className="title">Holiday Calendar</div>

        {/* Filter Controls */}
        <div className="filter-controls">
          <label className="filter-label">Year:</label>
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
            className="year-select"
          >
            {[currentYear - 1, currentYear, currentYear + 1].map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
          <Button className="btn-action action-button" style={{ width: "10%", maxHeight: "29px" }} onClick={handleFilter}>
            Show
          </Button>
        </div>

        <TableContainer component={Paper} className="table-container-leave-type">
          <Table className="table">
            <TableHead>
              <TableRow className="table-row">
                <TableCell className="header-cell">Sr. No.</TableCell>
                <TableCell className="header-cell">Date</TableCell>
                <TableCell className="header-cell">Day</TableCell>
                <TableCell className="header-cell">Particulars / Occasion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredHolidays.length === 0 ? (
                <TableRow>
                  <TableCell align="center" className="cell" colSpan={4}>
                    Data Not Available......
                  </TableCell>
                </TableRow>
              ) : (
                filteredHolidays
                  .sort((a, b) => new Date(a.HOLIDAY_DATE) - new Date(b.HOLIDAY_DATE))
                  .map((leave, index) => (
                    <TableRow className="table-row" key={leave.leaveTypeId}>
                      <TableCell align="center" className="cell">{index + 1}</TableCell>
                      <TableCell align="center" className="cell">{formatDate(leave.HOLIDAY_DATE)}</TableCell>
                      <TableCell align="center" className="cell">{moment(leave.HOLIDAY_DATE).format('dddd')}</TableCell>
                      <TableCell align="center" className="cell">{leave.HOLIDAY_TYP}</TableCell>
                    </TableRow>
                  ))
              )}
              {filteredHolidays.length < 5 &&
                Array.from({ length: 5 - filteredHolidays.length }).map((_, index) => (
                  <TableRow className="table-row empty-row" key={`empty-row-${index}`}>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}

export default Cust_Holliday_Calendar;