import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import services from "./apiServices";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import LveTypRegTab from "./LveTypRegTab";
import * as XLSX from "xlsx";

export default function Admin_Adduser_Affilt() {
  const [validationErrors, setValidationErrors] = useState({
    // Add validation error states as needed
  });

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${month}${year}`;
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      // Create a copy of the current state
      const updatedFields = {
        ...prevFormData,
        [name]: value, // Update the specific field
      };

      // Special logic to handle empid and birthdate changes
      if (name === "empid") {
        // If empid is cleared, also clear usernm and update usr_pass
        if (value === "") {
          updatedFields.usernm = ""; // Clear usernm
          updatedFields.usr_pass = ""; // Clear usr_pass
        } else {
          updatedFields.usernm = value; // Set usernm to empid value
          // Compute usr_pass with formatted birthdate first, then empid
          const formattedBirthdate = formatDate(updatedFields.birthdate);
          updatedFields.usr_pass = `${formattedBirthdate}${value}`;
        }
      } else if (name === "birthdate") {
        // Update usr_pass when birthdate changes
        const formattedBirthdate = formatDate(value);
        updatedFields.usr_pass = `${formattedBirthdate}${updatedFields.empid || ""
          }`;
      }

      return updatedFields;
    });
  };

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(""); // State to track selected radio button
  const [revdata, setrevdata] = useState({ tabdata: [], seledesgination: [] });

  const [formData, setFormData] = useState({
    usr_typ: "",
    userCategory: "",
    userPost: "",
    userTitle: "",
    usr_fnm: "",
    usr_mnm: "",
    usr_lnm: "",
    usr_dnm: "",
    usrgender: "",
    usernm: "",
    usr_pass: "",
    phone1: "",
    phone2: "",
    landlineno: "",
    cellphone: "",
    empid: "",
    ofcilemail: "",
    birthdate: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    pin: "",
    state: "",
    addtype: "",
    country: 100,
    email: "",
    landmark: "",
    gmapLink: "",
    geoloc: "",
    icrdno: "",
    aprval_id: "",
    emp_ctrct: "",
    prefadd: "",
    jondate: "",
    resdate: "",
    resnote: "",
  });
  console.log(formData);
  const [gender, setgender] = useState([]);

  const [usrdata, setUsrdata] = useState({
    gender: [],
    usrtype: [],
    usrcat: [],
    usrtitle: [],
    Desgn: [],
    add_type: [],
    user_contract: [],
  });
  // console.log(usrdata)
  const [data, setData] = useState({
    result: [],
    result2: [],
    country_res: [],
  });
  const [data1, setData1] = useState([])
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const handleChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  // Handle radio button change
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await services.getlvetypdata(revdata);

        if (result) {
          setData(result); // Wrap it in an object if necessary
          // console.log(result)
        } else {
          console.error("Unexpected data format:", result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const affdata = await services.getaffilatedata();
        if (affdata) {
          setData1(affdata);
          console.log("affdata", affdata);
        } else {
          console.log("Error for fetching data:");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch();
  }, []);
  console.log(data1);
  console.log(data);
  useEffect(() => {
    const fg = async () => {
      try {
        // Make multiple requests
        const res = await Promise.all([
          services.getdropdowndata("APCBL_GNDR"),
          services.getdropdowndata("User_Type"),
          services.getdropdowndata("User_Category"),
          services.getdropdowndata("User_Title"),
          services.getdropdowndata("APCBL_POST"),
          services.getdropdowndata("Address_Type"),
          services.getdropdowndata("EMP_NATUR"),
        ]);

        const newUsrdata = {
          usrgender: [],
          usrtype: [],
          usrcat: [],
          usrtitle: [],
          Desgn: [],
          add_type: [],
          user_contract: [],
        };

        // Process responses based on the name identifier
        res.forEach((response) => {
          const { name, dpresult } = response;

          switch (name) {
            case "APCBL_GNDR":
              newUsrdata.usrgender = dpresult;
              break;
            case "User_Type":
              newUsrdata.usrtype = dpresult;
              break;
            case "User_Category":
              newUsrdata.usrcat = dpresult;
              break;
            case "User_Title":
              newUsrdata.usrtitle = dpresult;
              break;
            case "APCBL_POST":
              newUsrdata.Desgn = dpresult;
              break;
            case "Address_Type":
              newUsrdata.add_type = dpresult;
              break;
            case "EMP_NATUR":
              newUsrdata.user_contract = dpresult;
              break;

            default:
              break;
          }
        });

        setUsrdata(newUsrdata);
      } catch (err) {
        // Handle errors
        setError(err);
      } finally {
        // Set loading to false when requests are completed
        setLoading(false);
      }
    };

    fg();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    // Log form data for debugging
    console.log("Form Data:", formData);
  };

  const emptyfieldvalidation = () => {
    const newErrors = {};

    // Check each field for emptiness
    // Entity fields validaation
    if (!formData.usr_typ) newErrors.usr_typ = "User Type is required";
    if (!formData.userCategory)
      newErrors.userCategory = "User Category is required";
    if (!formData.usr_fnm) newErrors.usr_fnm = "User First Name is required";
    if (!formData.usr_lnm) newErrors.usr_lnm = "User Last Name is required";
    if (!formData.phone1) newErrors.phone1 = "Phone 1 is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.userPost) newErrors.userPost = "User Post is required";
    if (!formData.empid) newErrors.empid = "Emp ID is required";
    if (!formData.usrgender) newErrors.usrgender = "Gender is required";
    if (!formData.birthdate) newErrors.birthdate = "birth date  is required";
    if (!formData.jondate) newErrors.jondate = "Joining date is required";
    if (!formData.icrdno) newErrors.icrdno = "Access ICRD No is required";
    if (!formData.aprval_id) newErrors.aprval_id = "Approval ID is required";
    if (!formData.emp_ctrct)
      newErrors.emp_ctrct = "Employee Contract is required";
    // Address fields validaation
    if (!formData.addressLine1)
      newErrors.addressLine1 = "Address Line 1 is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.pin) newErrors.pin = "PIN is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.ofcilemail)
      newErrors.ofcilemail = "official email is required";
    if (!formData.addtype) newErrors.addtype = "Address Type is required";
    if (!formData.prefadd) newErrors.prefadd = "Preferred Address is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Your existing method
  const sub_addusr_data = () => {
    if (emptyfieldvalidation()) {
      const insrt_tab1 = async () => {
        try {

          // Call the service with the combined data
          const insrt_res = await services.reg_adm_addusr_afflit(formData);

          console.log("Fetched data:", insrt_res);
          if (insrt_res) {
            console.log("User Added successfully in affiliate screen");
            alert("User Added successfully");
            setFormData([])
          } else {
            alert("Failed to Add User");
            console.error("Add User in affiliate screen insertion failed");
          }
        } catch (error) {
          alert("Failed to Add User. Please fill out all required fields once again");
          console.error("Add User in affiliate screen insertion failed", error);
        }
      };

      insrt_tab1();
    } else {
      alert("Please fill out all required fields");
    }
  };
  const [fileData, setFileData] = useState(null);
  console.log(fileData);

  const handle_excel_FileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Extract rows as arrays without headers

      // Map the columns based on their index and filter out empty rows
      const mappedData = jsonData
        .slice(1) // Skip the header row
        .filter((row) =>
          row.some((cell) => cell !== undefined && cell !== null && cell !== "")
        ) // Filter out empty rows
        .map((row) => ({
          col1: row[0] ?? null, // Use ?? null to set null if value is undefined
          col2: row[1] ?? null,
          col3: row[2] ?? null,
          col4: row[3] ?? null,
          col5: row[4] ?? null,
          col6: row[5] ?? null,
          col7: row[6] ?? null,
          col8: row[7] ?? null,
          col9: row[8] ?? null,
          col10: row[9] ?? null,
          col11: row[10] ?? null,
          col12: row[11] ?? null,
          col13: row[12] ?? null,
          col14: row[13] ?? null,
          col15: row[14] ?? null,
          col16: row[15] ?? null,
          col17: row[16] ?? null,
          col18: row[17] ?? null,
          col19: row[18] ?? null,
          col20: row[19] ?? null,
          col21: row[20] ?? null,
          col22: row[21] ?? null,
          col23: row[22] ?? null,
          col24: row[23] ?? null,
          col25: row[24] ?? null,
          col26: row[25] ?? null,
          col27: row[26] ?? null,
          col28: row[27] ?? null,
          col29: row[28] ?? null,
          col30: row[29] ?? null,
          col31: row[30] ?? null,
          col32: row[31] ?? null,
        }));

      setFileData(mappedData); // Store the filtered and mapped data
    };

    if (file) {
      reader.readAsArrayBuffer(file);
    }
  };

  const handle_upload_excel_submit = async (event) => {
    event.preventDefault();
    if (!fileData) {
      alert("Please upload a file.");
      return;
    }

    try {
      const response = await fetch(
        "/rdbs/register/insrt_adm_addusr_afflit_excel",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(fileData),
        }
      );
      if (response.ok) {
        alert("Data uploaded successfully!");
        console.log("Error:", error);
      } else {
        alert("Error uploading data.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="wrapper_form" style={{ paddingLeft: "0px" }}>
      <div className="title" style={{ marginTop: "0%" }}>Add User</div>
      <div className="form-container">
        {/* Radio Buttons and Content */}
        <div className="radio-row">
          <label>
            <input
              type="radio"
              name="addOption"
              value="adduser_from_excel"
              checked={selectedOption === "adduser_from_excel"}
              onChange={handleOptionChange}
              className="radio-input"
            />
            Add .CSV File
            <hr className="hr1" />
          </label>
          {selectedOption === "adduser_from_excel" && (
            <div className="radio-content">
              <form className="pdm" onSubmit={handle_upload_excel_submit}>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={handle_excel_FileUpload}
                />
                <br />
                <div className="centered-container">
                  <button
                    type="submit"
                    className="btn-action action-button upload-button"
                  >
                    Upload File
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>


        <div className="radio-row" style={{ marginBottom: "15px" }}>
          <label>
            <input
              type="radio"
              name="addOption"
              value="adduserManually"
              checked={selectedOption === "adduserManually"}
              onChange={handleOptionChange}
              style={{ width: "2%" }}
            />
            Add Manually
          </label>
          {selectedOption === "adduserManually" && (
            <form onSubmit={handleSubmit} method="Post" className="frm_wd">
              <div className="form-container">
                {/* Group fields as needed */}
                <hr className="hr_ttl" />
                <p className="personal-info-title">
                  Personal information
                </p>
                <hr className="hr_dt" />

                <div className="row custom-row-margin">
                  <div className="drp_ln_hg">
                    <label className="custom-dropdown-label">Title</label>
                    <div className="field">
                      <select
                        className="dropdown_p1"
                        value={formData.userTitle}
                        onChange={(e) => handleChange(e, "userTitle")}
                      >
                        <option value="">Select Title</option>
                        {usrdata.usrtitle.map((type) => (
                          <option value={type.ATTRIB_CD} key={type.ATTRIB_CD}>
                            {type.ATTRIB_NAME}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="column custom-column-margin">
                    <div className="field">
                      <span>
                        <i className="fas fa-phone"></i>
                      </span>
                      <input
                        type="text"
                        value={formData.usr_fnm || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, usr_fnm: e.target.value })
                        }
                      />
                      <label>
                        User First Name{" "}
                        {errors.usr_fnm && (
                          <span className="error-message">{errors.usr_fnm}</span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="column custom-column-margin">
                    <div className="field">
                      <span>
                        <i className="fas fa-phone"></i>
                      </span>
                      <input
                        type="text"
                        value={formData.usr_mnm || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, usr_mnm: e.target.value })
                        }
                      />
                      <label>
                        User Middle Name
                        {errors.usr_mnm && (
                          <span className="error-message">{errors.usr_mnm}</span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="column custom-column-margin">
                    <div className="field">
                      <span>
                        <i className="fas fa-phone"></i>
                      </span>
                      <input
                        type="text"
                        value={formData.usr_lnm || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, usr_lnm: e.target.value })
                        }
                      />
                      <label>
                        User Last Name
                        {errors.usr_lnm && (
                          <span className="error-message">{errors.usr_lnm}</span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="column">
                    <div className="field">
                      <input
                        type="text"
                        value={formData.usr_dnm || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, usr_dnm: e.target.value })
                        }
                      />
                      <label>
                        User Display Name
                        {errors.usr_dnm && (
                          <span className="error-message">{errors.usr_dnm}</span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="column">
                    <div className="field">
                      <input
                        type="date"
                        name="birthdate"
                        value={formData.birthdate || ""}
                        onChange={handleChange1}
                      />
                      <label>
                        Date of Birth{" "}
                        {errors.birthdate && (
                          <span className="error-message">{errors.birthdate}</span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="email"
                        value={formData.email || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                      />
                      <label>
                        Personal Email{" "}
                        {errors.email && (
                          <span className="error-message">{errors.email}</span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row custom-row-small-margin">
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      User Type{" "}
                      {errors.usr_typ && (
                        <span className="error-message">{errors.usr_typ}</span>
                      )}
                    </label>
                    <div className="field">
                      <select
                        className="dropdown_p1"
                        value={formData.usr_typ}
                        onChange={(e) => handleChange(e, "usr_typ")}
                      >
                        <option value="">Select User Type</option>
                        {usrdata.usrtype.map((type) => (
                          <option value={type.ATTRIB_CD} key={type.ATTRIB_CD}>
                            {type.ATTRIB_NAME}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      User Category{" "}
                      {errors.userCategory && (
                        <span className="error-message">{errors.userCategory}</span>
                      )}
                    </label>
                    <div className="field">
                      <span>
                        <i className="fas fa-unlock"></i>
                      </span>
                      <select
                        className="dropdown_p1"
                        value={formData.userCategory}
                        onChange={(e) => handleChange(e, "userCategory")}
                      >
                        <option value="">Select User Category</option>
                        {usrdata.usrcat.map((type) => (
                          <option value={type.ATTRIB_CD} key={type.ATTRIB_CD}>
                            {type.ATTRIB_NAME}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      User Gender{" "}
                      {errors.usrgender && (
                        <span className="error-message">{errors.usrgender}</span>
                      )}
                    </label>
                    <div className="field">
                      <span>
                        <i className="fas fa-unlock"></i>
                      </span>
                      <select
                        className="dropdown_p1"
                        value={formData.usrgender}
                        onChange={(e) => handleChange(e, "usrgender")}
                      >
                        <option value="">Select User Gender</option>
                        {usrdata.usrgender.map((type) => (
                          <option value={type.ATTRIB_CD} key={type.ATTRIB_CD}>
                            {type.ATTRIB_NAME}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row"></div>

                <hr className="hr_ttl" />
                <p className="official-details-title">
                  Official Details
                </p>
                <hr className="hr_dt hr-margin-bottom" />

                <div className="row custom-row-margin-small">
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="text"
                        name="empid"
                        value={formData.empid || ""}
                        onChange={handleChange1}
                      />
                      <label>
                        Emp ID{" "}
                        {errors.empid && (
                          <span className="error-message">{errors.empid}</span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="number"
                        value={formData.icrdno || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, icrdno: e.target.value })
                        }
                      />
                      <label>
                        Access ICRD No
                        {errors.icrdno && (
                          <span className="error-message">{errors.icrdno}</span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="number"
                        value={formData.aprval_id || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            aprval_id: e.target.value,
                          })
                        }
                      />
                      <label>
                        Approval ID :
                        {errors.aprval_id && (
                          <span className="error-message">{errors.aprval_id}</span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row custom-column-margin-small">
                  <div className="column drp_ln_hg">
                    <label className="custom-dropdown-label">User Name</label>
                    <div className="field">
                      <span>
                        <i className="fas fa-user"></i>
                      </span>
                      <input
                        type="text"
                        name="usernm"
                        value={formData.usernm || ""}
                        readOnly
                      />
                      {validationErrors.usernm && (
                        <span className="error-message">{validationErrors.usernm}</span>
                      )}
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="custom-dropdown-label">Password</label>
                    <div className="field">
                      <span>
                        <i className="fas fa-phone"></i>
                      </span>
                      <input
                        type="password"
                        value={formData.usr_pass || ""}
                        readOnly
                      />
                      {errors.usr_pass && (
                        <span className="error-message">{errors.usr_pass}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row custom-column-margin-small">
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="date"
                        value={formData.jondate || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, jondate: e.target.value })
                        }
                      />
                      <label>
                        Joining Date{" "}
                        {errors.jondate && (
                          <span className="error-message">{errors.jondate}</span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="email"
                        value={formData.ofcilemail || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            ofcilemail: e.target.value,
                          })
                        }
                      />
                      <label>
                        Official Email{"  "}
                        {errors.ofcilemail && (
                          <span className="error-message">{errors.ofcilemail}</span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row custom-column-margin-large">
                  <div className="column drp_ln_hg">
                    <label className="custom-dropdown-label">
                      Employee Nature{" "}
                      {errors.emp_ctrct && (
                        <span className="error-message">{errors.emp_ctrct}</span>
                      )}
                    </label>
                    <div className="field">
                      <select
                        className="dropdown_p1"
                        value={formData.emp_ctrct}
                        onChange={(e) => handleChange(e, "emp_ctrct")}
                      >
                        <option value="">Select Employee Nature</option>
                        {usrdata.user_contract.map((type) => (
                          <option value={type.ATTRIB_CD} key={type.ATTRIB_CD}>
                            {type.ATTRIB_NAME}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="custom-dropdown-label">
                      User Post{" "}
                      {errors.userPost && (
                        <span className="error-message">{errors.userPost}</span>
                      )}
                    </label>
                    <div className="field">
                      <select
                        className="dropdown_p1"
                        value={formData.userPost}
                        onChange={(e) => handleChange(e, "userPost")}
                      >
                        <option value="">Select User Post</option>
                        {usrdata.Desgn.map((type) => (
                          <option value={type.ATTRIB_CD} key={type.ATTRIB_CD}>
                            {type.ATTRIB_NAME}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row"></div>

                <hr className="hr_ttl" />
                <p
                  style={{
                    fontSize: "25px",
                    paddingTop: "5px",
                    lineHeight: "100%",
                  }}
                >
                  Address Details
                </p>
                <hr className="hr_dt" />
                <div className="row" style={{ marginBottom: "10px" }}>
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      Address Type{" "}
                      {errors.addtype && (
                        <span className="error-message">{errors.addtype}</span>
                      )}
                    </label>
                    <div className="field">
                      <span>
                        <i className="fas fa-unlock"></i>
                      </span>
                      <select
                        className="dropdown_p1"
                        value={formData.addtype}
                        onChange={(e) => handleChange(e, "addtype")}
                      >
                        <option value="">Select Address Type</option>
                        {usrdata.add_type.map((type) => (
                          <option value={type.ATTRIB_CD}>
                            {type.ATTRIB_NAME}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div
                    className="column "
                    style={{ display: "flex", alignItems: "center", marginTop: "5px", lineHeight: "12px" }}
                  >
                    <label style={{ marginLeft: "0%" }}>
                      {" "}
                      Is this Preferred Address ?
                      {errors.prefadd && (
                        <span className="error-message">{errors.prefadd}</span>
                      )}
                    </label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          // name="LVE_ALOW_IN_JOIN_MN_YN"
                          value={formData.prefadd || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              prefadd: e.target.value,
                            })
                          }
                        >
                          <FormControlLabel
                            style={{ padding: "2px" }}
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginBottom: "15px" }}></div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-address-book"></i>
                      </span>
                      <input
                        type="text"
                        value={formData.addressLine1 || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            addressLine1: e.target.value,
                          })
                        }
                      />
                      <label>
                        Address Line 1
                        {errors.addressLine1 && (
                          <span className="error-message">
                            {errors.addressLine1}
                          </span>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-address-book"></i>
                      </span>
                      <input
                        type="text"
                        value={formData.city || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, city: e.target.value })
                        }
                      />
                      <label>
                        City{" "}
                        {errors.city && (
                          <span className="error-message">{errors.city}</span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-address-book"></i>
                      </span>
                      <input
                        type="text"
                        value={formData.addressLine2 || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            addressLine2: e.target.value,
                          })
                        }
                      />
                      <label>Address Line 2</label>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="text"
                        value={formData.pin || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, pin: e.target.value })
                        }
                      />
                      <label>
                        PIN{" "}
                        {errors.pin && (
                          <span className="error-message">{errors.pin}</span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "1px" }}>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="text"
                        value={formData.addressLine3 || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            addressLine3: e.target.value,
                          })
                        }
                      />
                      <label>Address Line 3</label>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="text"
                        value={formData.state || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, state: e.target.value })
                        }
                      />
                      <label>State</label>
                      {errors.state && (
                        <span className="error-message">{errors.state}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Country</label>
                    <div className="">
                      <span>
                        <i className="fas fa-unlock"></i>
                      </span>
                      <select
                        // style={{ width: "80%" }}
                        className="dropdown_p1"
                        onChange={(e) => handleChange(e, "country")}
                        value="100"
                      >
                        <option value="">---Select Country---</option>
                        {data.country_res.map((type) => (
                          <option value={type.CNTRY_KY}>{type.CNTRY_NM}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Landmark</label>
                    <div className="field">
                      <input
                        // className="ys"
                        type="text"
                        value={formData.landmark || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, landmark: e.target.value })
                        }
                      />

                      {errors.landmark && (
                        <span className="error-message">{errors.landmark}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="text"
                        value={formData.gmapLink || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, gmapLink: e.target.value })
                        }
                      />
                      <label>Google Map Link</label>
                      {errors.gmapLink && (
                        <span className="error-message">{errors.gmapLink}</span>
                      )}
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="text"
                        value={formData.geoloc || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, geoloc: e.target.value })
                        }
                      />
                      <label>Branch Location</label>
                      {errors.geoloc && (
                        <span className="error-message">{errors.geoloc}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="number"
                        value={formData.phone1 || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, phone1: e.target.value })
                        }
                      />
                      <label>
                        Phone 1{" "}
                        {errors.phone1 && (
                          <span className="error-message">{errors.phone1}</span>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="number"
                        value={formData.phone2 || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, phone2: e.target.value })
                        }
                      />
                      <label>Phone 2</label>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="number"
                        value={formData.landlineno || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            landlineno: e.target.value,
                          })
                        }
                      />
                      <label>Landline No</label>
                      {errors.landlineno && (
                        <span className="error-message">
                          {errors.landlineno}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="number"
                        value={formData.cellphone || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            cellphone: e.target.value,
                          })
                        }
                      />
                      <label>Cell Phone</label>
                      {errors.cellphone && (
                        <span className="error-message">
                          {errors.cellphone}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <hr className="hr_dt" style={{ marginTop: "3%" }} />
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column"></div>
                  <div className="column"></div>
                  <div className="column"></div>
                  <div className="column"></div>
                  <div className="column">
                    <div className="field">
                      <button
                        type="submit"
                        className="btn-action action-button"
                        onClick={sub_addusr_data}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <hr
                  className="hr_dt"
                  style={{ marginTop: "1.5%", }}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
