import React, { useState, useEffect } from "react";
import services from "./apiServices";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

export default function Dash_Cust() {
    const storedUserData = sessionStorage.getItem("user_data");
    let usr_log = null;
    if (storedUserData) {
        usr_log = JSON.parse(storedUserData);
    }
    const [data, setData] = useState({});
    // console.log("Login user data..", usr_log[0].USR_NTT_ID)
    const [leavecnt, Setleavecnt] = useState({
        login_lve_bal_cnt: [], lve_apl_dt: [], login_team_membr_inf_res: [],
        shw_upcom_hlod_res: [], empwise_lve_bal_res: [], all_usr_cnt_data: [], aflt_annocement_news_result: []
    })

    const oboApplications = leavecnt.lve_apl_dt.filter(application => application.APP_OBO_ID);
    console.log("oboApplications", oboApplications)


    useEffect(() => {
        const lev_bal_cnt = async () => {
            try {
                const lve_cnt = await services.get_lev_bal_cnt();

                if (lve_cnt) {
                    Setleavecnt(lve_cnt); // Wrap it in an object if necessary
                } else {
                    console.error("Unexpected data format:", lve_cnt);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        lev_bal_cnt();

    }, [])

    console.log("leavecnt", leavecnt)
    useEffect(() => {
        const fetch = async () => {
            try {
                const affdata = await services.getaffilatedata();
                console.log("affdata", affdata)
                if (affdata) {
                    setData(affdata);
                    console.log("affdata", affdata);
                } else {
                    console.log("Error for fetching data:");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch();
    }, []);
    console.log("datra", data)

    function formatDate(dateString) {
        // Format the date to 'DD/MMM/YYYY' with the first letter of the month capitalized
        return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
    }
    // Get the last 10 leave applications
    const last10Applications = leavecnt.lve_apl_dt
        .sort((a, b) => new Date(b.APP_DT) - new Date(a.APP_DT)) // Sort by application date descending
        .slice(0, 10); // Get the first 10 entries


    //for Annocements    
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState('');
    const [isPaused, setIsPaused] = useState(false);


    const handleMouseEnter = () => {
        setIsPaused(true); // Pause the marquee
    };

    const handleMouseLeave = () => {
        setIsPaused(false); // Resume the marquee
    };

    const handleAnnouncementClick = (announcement) => {
        setSelectedAnnouncement(announcement);
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
        setSelectedAnnouncement('');
    };
    console.log("selectedAnnouncement", selectedAnnouncement)

    const marqueeItems = leavecnt.aflt_annocement_news_result.map((app, index) => (
        <span key={app.LVE_NEWS_KEY} // Assuming LVE_NEWS_KEY is unique
            onClick={() => handleAnnouncementClick(app)} // Assuming app holds the full announcement text
            style={{ cursor: 'pointer', marginRight: '20px' }} // Add pointer cursor and margin
        >
            {app.LVE_NTC_HEAD_SUB}
            {index < leavecnt.aflt_annocement_news_result.length - 1 && <span className="pipe">|</span>} {/* Add pipe with CSS class */}
        </span>
    ));
    return (
        <>
            <div
                className={`marquee ${isPaused ? 'paused' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="marquee-content">{marqueeItems}</div>
            </div>

            {/* Popup for Announcement */}
            <Dialog open={popupOpen} onClose={handleClosePopup} maxWidth="lg" fullWidth>
                <DialogTitle className="dialog-title">
                    Announcement Details
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClosePopup}
                        aria-label="close"
                        className="close-button"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <Typography className="announcement-heading">
                        Announcement Heading :- <b>{selectedAnnouncement.LVE_NTC_HEAD_SUB}</b>
                    </Typography>
                    <Typography className="announcement-text">
                        Actual Announcement :- <b>{selectedAnnouncement.LVE_ACTUL_NTC}</b>
                    </Typography>
                    {/* Add more fields as needed */}
                </DialogContent>
            </Dialog>

            <div className="wrapper_form">
                <Grid item xs={12} className="grid-item">
                    <div className="title">Upcoming Holidays</div>
                    <TableContainer
                        component={Paper}
                        className="table-container"
                    >
                        <Table className="table">
                            <TableHead>
                                <TableRow className="table-row">
                                    <TableCell className="header-cell">Sr. No.</TableCell>
                                    <TableCell className="header-cell">Date</TableCell>
                                    <TableCell className="header-cell">Day</TableCell>
                                    <TableCell className="header-cell">Particulars / Occasion</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leavecnt.shw_upcom_hlod_res
                                    .sort((a, b) => new Date(a.HOLIDAY_DATE) - new Date(b.HOLIDAY_DATE)) // Sort by HOLIDAY_DATE
                                    .map((leave, index) => (
                                        <TableRow className="table-row" key={leave.leaveTypeId}>
                                            <TableCell className="cell">{index + 1}</TableCell>
                                            <TableCell className="cell">{formatDate(leave.HOLIDAY_DATE)}</TableCell>
                                            <TableCell className="cell">{moment(leave.HOLIDAY_DATE).format('dddd')}</TableCell>
                                            <TableCell className="cell">{leave.HOLIDAY_TYP}</TableCell>
                                        </TableRow>
                                    ))}
                                {/* Add empty rows if there are less than 5 rows */}
                                {leavecnt.shw_upcom_hlod_res.length < 5 &&
                                    Array.from({ length: 5 - leavecnt.shw_upcom_hlod_res.length }).map((_, index) => (
                                        <TableRow className="table-row" key={`empty-row-${index}`}>
                                            <TableCell className="cell">&nbsp;</TableCell>
                                            <TableCell className="cell">&nbsp;</TableCell>
                                            <TableCell className="cell">&nbsp;</TableCell>
                                            <TableCell className="cell">&nbsp;</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <hr className="hr-style" />
                <Grid container spacing={2} justifyContent="center">
                    {/* Left Table */}
                    <Grid item xs={6} className="grid-item">
                        <div className="title">Leave Balance Details</div>
                        <TableContainer
                            component={Paper}
                            className="table-container-leave-type"
                        >
                            <Table className="table">
                                <TableHead>
                                    <TableRow className="table-row">
                                        <TableCell className="header-cell">Leave Type</TableCell>
                                        <TableCell className="header-cell">Op. Bal.</TableCell>
                                        <TableCell className="header-cell">Added in Year</TableCell>
                                        <TableCell className="header-cell">Consume</TableCell>
                                        <TableCell className="header-cell">Balance</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {leavecnt.login_lve_bal_cnt.slice(0, 10).map((leave) => (
                                        <TableRow className="table-row" key={leave.leaveTypeId}>
                                            <TableCell className="cell">{leave.disp_nm}</TableCell>
                                            <TableCell className="cell">{leave.LVE_TYP_OP_BAL_CNT}</TableCell>
                                            <TableCell className="cell">&nbsp;</TableCell>
                                            <TableCell className="cell">{leave.LVE_TYP_CNMSN_CNT}</TableCell>
                                            <TableCell className="cell">{leave.LVE_TYP_RN_BAL_CNT}</TableCell>
                                        </TableRow>
                                    ))}
                                    {/* Add empty rows if there are less than 10 rows */}
                                    {leavecnt.login_lve_bal_cnt.length < 10 &&
                                        Array.from({ length: 10 - leavecnt.login_lve_bal_cnt.length }).map((_, index) => (
                                            <TableRow className="table-row" key={`empty-row-${index}`}>
                                                <TableCell className="cell">&nbsp;</TableCell>
                                                <TableCell className="cell">&nbsp;</TableCell>
                                                <TableCell className="cell">&nbsp;</TableCell>
                                                <TableCell className="cell">&nbsp;</TableCell>
                                                <TableCell className="cell">&nbsp;</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={6} className="grid-item">
                        <div className="title">Last 10 Leave Application Status</div>
                        <TableContainer
                            component={Paper}
                            className="table-container"
                        >
                            <Table className="table">
                                <TableHead>
                                    <TableRow className="table-row">
                                        <TableCell className="header-cell">Date</TableCell>
                                        <TableCell className="header-cell">From</TableCell>
                                        <TableCell className="header-cell">To</TableCell>
                                        <TableCell className="header-cell">Duration</TableCell>
                                        <TableCell className="header-cell">Type</TableCell>
                                        <TableCell className="header-cell">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {last10Applications.slice(0, 10).map((aplicantdata) => (
                                        <TableRow className="table-row" key={aplicantdata.leaveTypeId}>
                                            <TableCell className="cell">{formatDate(aplicantdata.APP_DT)}</TableCell>
                                            <TableCell className="cell">{formatDate(aplicantdata.APP_LVE_FRM_DT)}</TableCell>
                                            <TableCell className="cell">{formatDate(aplicantdata.APP_LVE_TO_DT)}</TableCell>
                                            <TableCell className="cell">{aplicantdata.APP_LVE_DES} days</TableCell>
                                            <TableCell className="cell">{aplicantdata.disp_nm}</TableCell>
                                            <TableCell className="cell">{aplicantdata.APP_LVE_STTS}</TableCell>
                                        </TableRow>
                                    ))}
                                    {last10Applications.length < 10 &&
                                        Array.from({ length: 10 - last10Applications.length }).map((_, index) => (
                                            <TableRow className="table-row" key={`empty-row-manager-${index}`}>
                                                <TableCell className="cell">&nbsp;</TableCell>
                                                <TableCell className="cell">&nbsp;</TableCell>
                                                <TableCell className="cell">&nbsp;</TableCell>
                                                <TableCell className="cell">&nbsp;</TableCell>
                                                <TableCell className="cell">&nbsp;</TableCell>
                                                <TableCell className="cell">&nbsp;</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <hr className="hr-style" />
                <Grid item xs={12} className="grid-item">
                    <div className="title">OBO Applications</div>
                    <TableContainer
                        component={Paper}
                        className="table-container"
                    >
                        <Table className="table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="header-cell">Sr.No.</TableCell>
                                    <TableCell className="header-cell">OBO ID</TableCell>
                                    <TableCell className="header-cell">Date</TableCell>
                                    <TableCell className="header-cell">From</TableCell>
                                    <TableCell className="header-cell">To</TableCell>
                                    <TableCell className="header-cell">Duration</TableCell>
                                    <TableCell className="header-cell">Type</TableCell>
                                    <TableCell className="header-cell">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {oboApplications.map((application, index) => (
                                    <TableRow key={application.APP_OBO_ID}>
                                        <TableCell align="center" className="cell">{index + 1}</TableCell>
                                        <TableCell align="center" className="cell">{application.APP_OBO_ID}</TableCell>
                                        <TableCell align="center" className="cell">{formatDate(application.APP_DT)}</TableCell>
                                        <TableCell align="center" className="cell">{formatDate(application.APP_LVE_FRM_DT)}</TableCell>
                                        <TableCell align="center" className="cell">{formatDate(application.APP_LVE_TO_DT)}</TableCell>
                                        <TableCell align="center" className="cell">{application.APP_LVE_DES} days</TableCell>
                                        <TableCell align="center" className="cell">{application.disp_nm}</TableCell>
                                        <TableCell align="center" className="cell">{application.APP_LVE_STTS}</TableCell>
                                    </TableRow>
                                ))}
                                {oboApplications.length < 5 &&
                                    Array.from({ length: 5 - oboApplications.length }).map((_, index) => (
                                        <TableRow key={`empty-row-${index}`}>
                                            <TableCell align="center" className="cell">&nbsp;</TableCell>
                                            <TableCell align="center" className="cell">&nbsp;</TableCell>
                                            <TableCell align="center" className="cell">&nbsp;</TableCell>
                                            <TableCell align="center" className="cell">&nbsp;</TableCell>
                                            <TableCell align="center" className="cell">&nbsp;</TableCell>
                                            <TableCell align="center" className="cell">&nbsp;</TableCell>
                                            <TableCell align="center" className="cell">&nbsp;</TableCell>
                                            <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <hr className="hr-style" />
            </div>
        </>
    );
}
