import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";

export default function Cust_Rep_Lve_Bal_Consume() {

  const [leavecnt, Setleavecnt] = useState({
    login_lve_bal_cnt: [], lve_apl_dt: [], login_team_membr_inf_res: [],
    shw_upcom_hlod_res: [], empwise_lve_bal_res: [], all_usr_cnt_data: [], aflt_annocement_news_result: []
  })

  useEffect(() => {
    const lev_bal_cnt = async () => {
      try {
        const lve_cnt = await services.get_lev_bal_cnt();

        if (lve_cnt) {
          Setleavecnt(lve_cnt); // Wrap it in an object if necessary
        } else {
          console.error("Unexpected data format:", lve_cnt);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    lev_bal_cnt();

  }, [])

  console.log("leavecnt", leavecnt)

  return (
    <div className="wrapper_form">
      <Grid item xs={6} className="grid-item">
        <div className="title">Leave Balance & Consumption Details</div>
        <TableContainer component={Paper} className="table-container-leave-type">
          <Table className="table">
            <TableHead>
              <TableRow className="table-row">
                <TableCell className="header-cell">Leave Type</TableCell>
                <TableCell className="header-cell">Op. Bal.</TableCell>
                <TableCell className="header-cell">Consume</TableCell>
                <TableCell className="header-cell">Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leavecnt.login_lve_bal_cnt.slice(0, 10).map((leave) => (
                <TableRow className="table-row" key={leave.leaveTypeId}>
                  <TableCell className="cell">{leave.disp_nm}</TableCell>
                  <TableCell className="cell">{leave.LVE_TYP_OP_BAL_CNT}</TableCell>
                  <TableCell className="cell">{leave.LVE_TYP_CNMSN_CNT}</TableCell>
                  <TableCell className="cell">{leave.LVE_TYP_RN_BAL_CNT}</TableCell>
                </TableRow>
              ))}
              {/* Add empty rows if there are less than 10 rows */}
              {leavecnt.login_lve_bal_cnt.length < 10 &&
                Array.from({ length: 10 - leavecnt.login_lve_bal_cnt.length }).map((_, index) => (
                  <TableRow className="table-row empty-row" key={`empty-row-${index}`}>
                    <TableCell className="cell">&nbsp;</TableCell>
                    <TableCell className="cell">&nbsp;</TableCell>
                    <TableCell className="cell">&nbsp;</TableCell>
                    <TableCell className="cell">&nbsp;</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  )
}
