import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";

const headerCellStyle = {
  fontSize: "0.8rem",
  padding: "1px 18px",
  backgroundColor: "#4a6d8c",
  border: "1px solid gray",
  color: "white",
  textAlign: "center",
};

const cellStyle = {
  fontSize: "0.8rem",
  padding: "1px 18px",
  border: "1px solid gray",
};

function System_Adm_Dash() {
  const [aflttotalcnt, setaflttotalcnt] = useState({ aflt_cnt_res: [], aflt_wise_usr_cnt_res: [], alt_wise_lve_types_res: [], aflt_wise_lve_apl_cnt_res: [], pendng_aprved_aflt_apl_cnt_res: [] });

  const [groupedUsers, setGroupedUsers] = useState({});
  const [groupedLeaveTypes, setGroupedLeaveTypes] = useState({});

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await services.get_total_aflt_cnt();
        if (res) {
          setaflttotalcnt(res);
          groupUsersByAffiliate(res.aflt_wise_usr_cnt_res);
          groupLeaveTypesByAffiliate(res.alt_wise_lve_types_res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch();
  }, []);

  const groupUsersByAffiliate = (users) => {
    console.log("Fetched Users:", users); // Log the users data to check what we are working with

    // Temporarily skip filtering to see all the users
    const allUsers = users; // All users before filtering

    console.log("All Users:", allUsers); // Log all users to verify

    // Group users by their affiliate ID (AFLT_ID_BY_CUST)
    const grouped = allUsers.reduce((acc, user) => {
      const affiliateId = user.AFLT_ID_BY_CUST; // Assuming this is the affiliate ID
      if (!acc[affiliateId]) {
        acc[affiliateId] = [];
      }
      acc[affiliateId].push(user);
      return acc;
    }, {});

    console.log("Grouped Users:", grouped); // Log the grouped users to verify the output
    setGroupedUsers(grouped);
  };

  const groupLeaveTypesByAffiliate = (leaveTypes) => {
    const grouped = leaveTypes.reduce((acc, leaveType) => {
      const affiliateId = leaveType.AFLT_ID_BY_CUST; // Assuming this is the affiliate ID
      if (!acc[affiliateId]) {
        acc[affiliateId] = [];
      }
      acc[affiliateId].push(leaveType);
      return acc;
    }, {});
    setGroupedLeaveTypes(grouped);
  };

  const totalAffiliateCount = aflttotalcnt.aflt_cnt_res.length;
  const totalUserCount = aflttotalcnt.aflt_wise_usr_cnt_res.length;
  console.log("aflttotalcnt", aflttotalcnt)
  return (
    <div className="wrapper_form pd11" >

      <Grid item xs={12} className="grid-item">
        <div className="title">
          Total Client Count
        </div>
        <TableContainer component={Paper} className="table-container">
          <Table className="table">
            <TableHead>
              <TableRow className="table-row">
                <TableCell className="header-cell">Sr.No.</TableCell>
                <TableCell className="header-cell">Client ID</TableCell>
                <TableCell className="header-cell">Organization Unique identification</TableCell>
                <TableCell className="header-cell">Organization Short Name</TableCell>
                <TableCell className="header-cell">Client Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aflttotalcnt.aflt_cnt_res.map((afltcntdt, index) => (
                <TableRow key={afltcntdt.leaveTypeId}>
                  <TableCell align="center" className="cell">
                    {index + 1}
                  </TableCell>
                  <TableCell align="center" className="cell">
                    {afltcntdt.AFLT_KY}
                  </TableCell>
                  <TableCell align="center" className="cell">
                    {afltcntdt.AFLT_ID_BY_CUST}
                  </TableCell>
                  <TableCell align="center" className="cell">
                    {afltcntdt.AFLT_SHORT_NAME}
                  </TableCell>
                  <TableCell align="center" className="cell">
                    {afltcntdt.AFLT_NAME}
                  </TableCell>
                </TableRow>
              ))}
              {aflttotalcnt.aflt_cnt_res.length < 2 &&
                Array.from({ length: 2 - aflttotalcnt.aflt_cnt_res.length }).map((_, index) => (
                  <TableRow key={`empty-row-manager-${index}`} className="empty-row">
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell align="center" className="cell bold-font">
                  Total Client
                </TableCell>
                <TableCell align="center" className="cell">&nbsp;</TableCell>
                <TableCell align="center" className="cell">&nbsp;</TableCell>
                <TableCell align="center" className="cell">&nbsp;</TableCell>
                <TableCell align="center" className="cell bold-font">
                  {totalAffiliateCount}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <hr className="hr-style" />

      <Grid item xs={12} className="grid-item">
        <div className="title">
          Client Wise Total User Count
        </div>
        <TableContainer component={Paper} className="table-container">
          <Table className="table">
            <TableHead>
              <TableRow className="table-row">
                <TableCell className="header-cell">Sr.No.</TableCell>
                <TableCell className="header-cell">Client Name</TableCell>
                <TableCell className="header-cell">Client Admin</TableCell>
                <TableCell className="header-cell">User Name</TableCell>
                <TableCell className="header-cell">Post</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedUsers).flatMap(([affiliateId, users], index) => {
                const affiliateRows = users.map((user, userIndex) => (
                  <TableRow key={`${affiliateId}-${userIndex}`} className="table-row">
                    {userIndex === 0 && (
                      <>
                        <TableCell rowSpan={users.length} align="center" className="cell">
                          {index + 1}
                        </TableCell>
                        <TableCell rowSpan={users.length} align="center" className="cell">
                          {user.AFLT_NAME}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="center" className="cell">
                      {user['Admin Usr']}
                    </TableCell>
                    <TableCell align="center" className="cell">
                      {user.USR_DISPLAY_NAME}
                    </TableCell>
                    <TableCell align="center" className="cell">
                      {user.ATTRIB_NAME}
                    </TableCell>
                  </TableRow>
                ));

                affiliateRows.push(
                  <TableRow key={`${affiliateId}-empty-row`} className="empty-row">
                    <TableCell colSpan={5} className="cell">&nbsp;</TableCell>
                  </TableRow>
                );

                return affiliateRows;
              })}

              {Object.keys(groupedUsers).length < 2 &&
                Array.from({ length: 2 - Object.keys(groupedUsers).length }).map((_, index) => (
                  <TableRow key={`empty-row-${index}`} className="empty-row">
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell align="center" className="cell bold-font">
                  Total Users
                </TableCell>
                <TableCell align="center" className="cell">&nbsp;</TableCell>
                <TableCell align="center" className="cell">&nbsp;</TableCell>
                <TableCell align="center" className="cell">&nbsp;</TableCell>
                <TableCell align="center" className="cell bold-font">
                  {totalUserCount}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <hr className="hr-style" />

      <Grid item xs={12} className="grid-item">
        <div className="title title-center">
          Client Wise Leave Types
        </div>
        <TableContainer component={Paper} className="table-container-leave-type">
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell className="header-cell">Sr. No</TableCell>
                <TableCell className="header-cell">Client Name</TableCell>
                <TableCell className="header-cell">Organization Short Name</TableCell>
                <TableCell className="header-cell">Leave Types</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedLeaveTypes).flatMap(([affiliateId, leaveTypes], index) => {
                const leaveTypeRows = leaveTypes.map((leaveType, leaveIndex) => (
                  <TableRow key={`${affiliateId}-${leaveIndex}`} className="table-row">
                    {leaveIndex === 0 && (
                      <>
                        <TableCell rowSpan={leaveTypes.length} align="center" className="cell">
                          {index + 1}
                        </TableCell>
                        <TableCell rowSpan={leaveTypes.length} align="center" className="cell">
                          {leaveType.AFLT_NAME}
                        </TableCell>
                        <TableCell rowSpan={leaveTypes.length} align="center" className="cell">
                          {leaveType.AFLT_SHORT_NAME}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="center" className="cell">
                      {leaveType.disp_nm}
                    </TableCell>
                  </TableRow>
                ));

                leaveTypeRows.push(
                  <TableRow key={`${affiliateId}-empty-row`} className="empty-row">
                    <TableCell colSpan={4} className="cell">&nbsp;</TableCell>
                  </TableRow>
                );

                return leaveTypeRows;
              })}

              {Object.keys(groupedLeaveTypes).length < 2 &&
                Array.from({ length: 2 - Object.keys(groupedLeaveTypes).length }).map((_, index) => (
                  <TableRow key={`empty-row-${index}`} className="empty-row">
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <hr className="hr-style" />

      <Grid item xs={12} className="grid-item">
        <div className="title">
          Client Wise Leave Application Count
        </div>
        <TableContainer component={Paper} className="table-container">
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell className="header-cell">Sr. No</TableCell>
                <TableCell className="header-cell">Client Name</TableCell>
                <TableCell className="header-cell">Organization Short Name</TableCell>
                <TableCell className="header-cell">Leave Type</TableCell>
                <TableCell className="header-cell">Application Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(
                aflttotalcnt.aflt_wise_lve_apl_cnt_res.reduce((acc, leaveApp) => {
                  const key = `${leaveApp.AFLT_ID_BY_CUST}-${leaveApp.AFLT_NAME}-${leaveApp.AFLT_SHORT_NAME}`;
                  if (!acc[key]) {
                    acc[key] = { ...leaveApp, count: 1 };
                  } else {
                    acc[key].count += 1;
                  }
                  return acc;
                }, {})
              ).map(([key, leaveApp], index) => {
                const leaveTypeRows = aflttotalcnt.aflt_wise_lve_apl_cnt_res.filter(
                  (app) =>
                    app.AFLT_ID_BY_CUST === leaveApp.AFLT_ID_BY_CUST &&
                    app.AFLT_NAME === leaveApp.AFLT_NAME &&
                    app.AFLT_SHORT_NAME === leaveApp.AFLT_SHORT_NAME
                );

                const rows = leaveTypeRows.map((leaveType, leaveIndex) => (
                  <TableRow key={`${leaveType.APP_TYP}-${index}`} className="table-row">
                    {leaveIndex === 0 && (
                      <>
                        <TableCell rowSpan={leaveTypeRows.length} align="center" className="cell">
                          {index + 1}
                        </TableCell>
                        <TableCell rowSpan={leaveTypeRows.length} align="center" className="cell">
                          {leaveApp.AFLT_NAME}
                        </TableCell>
                        <TableCell rowSpan={leaveTypeRows.length} align="center" className="cell">
                          {leaveApp.AFLT_SHORT_NAME}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="center" className="cell">
                      {leaveType.disp_nm}
                    </TableCell>
                    <TableCell align="center" className="cell">
                      {leaveType.Application_Count}
                    </TableCell>
                  </TableRow>
                ));

                rows.push(
                  <TableRow key={`${leaveApp.AFLT_NAME}-empty-row`} className="empty-row">
                    <TableCell colSpan={5} className="cell">&nbsp;</TableCell>
                  </TableRow>
                );

                return rows;
              })}

              {aflttotalcnt.aflt_wise_lve_apl_cnt_res.length < 2 &&
                Array.from({ length: 2 - aflttotalcnt.aflt_wise_lve_apl_cnt_res.length }).map(
                  (_, index) => (
                    <TableRow key={`empty-row-leave-app-${index}`} className="empty-row">
                      <TableCell align="center" className="cell">
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" className="cell">
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" className="cell">
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" className="cell">
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" className="cell">
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  )
                )}

              <TableRow>
                <TableCell align="center" className={`cell bold-font`}>
                  Total Applications
                </TableCell>
                <TableCell align="center" className={`cell bold-font`}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" className={`cell bold-font`}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" className={`cell bold-font`}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" className={`cell bold-font`}>
                  {aflttotalcnt.aflt_wise_lve_apl_cnt_res.reduce(
                    (total, leaveApp) => total + leaveApp.Application_Count,
                    0
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <hr className="hr-style" />

      <Grid item xs={12} className="grid-item">
        <div className="title">
          Client Wise Approve and Pending Applications Count
        </div>
        <TableContainer component={Paper} className="table-container">
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell className="header-cell">Sr. No</TableCell>
                <TableCell className="header-cell">Client Name</TableCell>
                <TableCell className="header-cell">Organization Short Name</TableCell>
                <TableCell className="header-cell">Leave Type</TableCell>
                <TableCell className="header-cell">Leave Status</TableCell>
                <TableCell className="header-cell">Application Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(
                aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.reduce((acc, leaveApp) => {
                  const key = `${leaveApp.AFLT_NAME}-${leaveApp.AFLT_SHORT_NAME}`;
                  if (!acc[key]) {
                    acc[key] = {
                      ...leaveApp,
                      leaveTypes: [
                        {
                          Leave_Type: leaveApp.Leave_Type,
                          APP_LVE_STTS: leaveApp.APP_LVE_STTS,
                          Application_Count: leaveApp.Application_Count,
                        },
                      ],
                    };
                  } else {
                    acc[key].leaveTypes.push({
                      Leave_Type: leaveApp.Leave_Type,
                      APP_LVE_STTS: leaveApp.APP_LVE_STTS,
                      Application_Count: leaveApp.Application_Count,
                    });
                  }
                  return acc;
                }, {})
              ).map(([key, leaveApp], index) => {
                const leaveTypeRows = leaveApp.leaveTypes.map((leaveType, leaveIndex) => (
                  <TableRow
                    key={`${leaveApp.AFLT_NAME}-${leaveType.Leave_Type}-${leaveType.APP_LVE_STTS}`}
                    className="table-row"
                  >
                    {leaveIndex === 0 && (
                      <>
                        <TableCell rowSpan={leaveApp.leaveTypes.length} align="center" className="cell">
                          {index + 1}
                        </TableCell>
                        <TableCell rowSpan={leaveApp.leaveTypes.length} align="center" className="cell">
                          {leaveApp.AFLT_NAME}
                        </TableCell>
                        <TableCell rowSpan={leaveApp.leaveTypes.length} align="center" className="cell">
                          {leaveApp.AFLT_SHORT_NAME}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="center" className="cell">
                      {leaveType.Leave_Type}
                    </TableCell>
                    <TableCell align="center" className="cell">
                      {leaveType.APP_LVE_STTS}
                    </TableCell>
                    <TableCell align="center" className="cell">
                      {leaveType.Application_Count}
                    </TableCell>
                  </TableRow>
                ));

                leaveTypeRows.push(
                  <TableRow key={`${leaveApp.AFLT_NAME}-empty-row`} className="empty-row">
                    <TableCell colSpan={6} className="cell">&nbsp;</TableCell>
                  </TableRow>
                );

                return leaveTypeRows;
              })}

              <TableRow>
                <TableCell align="center" className="cell bold-font">
                  Total Applications
                </TableCell>
                <TableCell align="center" className="cell bold-font">
                  &nbsp;
                </TableCell>
                <TableCell align="center" className="cell bold-font">
                  &nbsp;
                </TableCell>
                <TableCell align="center" className="cell bold-font">
                  &nbsp;
                </TableCell>
                <TableCell align="center" className="cell bold-font">
                  &nbsp;
                </TableCell>
                <TableCell align="center" className="cell bold-font">
                  {Object.entries(
                    aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.reduce((acc, leaveApp) => {
                      const key = `${leaveApp.AFLT_NAME}-${leaveApp.AFLT_SHORT_NAME}`;
                      if (!acc[key]) {
                        acc[key] = { totalCount: 0 };
                      }
                      acc[key].totalCount += leaveApp.Application_Count;
                      return acc;
                    }, {})
                  ).reduce((total, [key, value]) => total + value.totalCount, 0)}
                </TableCell>
              </TableRow>

              {aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.length < 2 &&
                Array.from({ length: 2 - aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.length }).map(
                  (_, index) => (
                    <TableRow key={`empty-row-leave-app-${index}`} className="empty-row">
                      <TableCell align="center" className="cell">
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" className="cell">
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" className="cell">
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" className="cell">
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" className="cell">
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <hr className="hr-style" />

    </div >
  );
}

export default System_Adm_Dash;