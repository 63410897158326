import React, { useState, useEffect } from "react";
import services from "./apiServices";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

export default function Dashboard_Adm() {

  const headerCellStyle = {
    fontSize: "0.8rem",
    padding: "1px 18px",
    backgroundColor: "#4a6d8c",
    border: "1px solid gray",
    color: "white",
    textAlign: "center"
  };

  const tableCellStyle = {
    fontSize: "0.8rem",
    padding: "1px 18px",
    border: "1px solid gray",
    textAlign: "center"
  };



  const formatDate1 = (isoDateString) => {
    // Check for null or empty strings
    if (!isoDateString || typeof isoDateString !== 'string' || isoDateString.trim() === "") {
      return "N/A"; // Return "N/A" for null or empty strings
    }

    // Create a Date object from the ISO string
    const date = new Date(isoDateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.warn(`Invalid date format: ${isoDateString}`);
      return "Invalid Date"; // Return "Invalid Date" for unexpected formats
    }

    // Define an array for month names (abbreviated)
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get day, month, and year
    const day = date.getUTCDate(); // Use UTC date
    const monthIndex = date.getUTCMonth(); // Use UTC month
    const year = date.getUTCFullYear(); // Use UTC year

    // Format the date as 'DD MMM YYYY'
    return `${day}/${monthNames[monthIndex]}/${year}`;
  };
  const [data, setData] = useState({ result: [] });
  const [data1, setData1] = useState({
    result: [],
    resul1: [],
    result2: [],
    result3: [],
  });
  const [entity, setEntity] = useState();
  const [selectedApp, setSelectedApp] = useState();
  const [selectedApp1, setSelectedApp1] = useState();
  const [approvalComment, setApprovalComment] = useState("");
  const [leaveStatuses, setLeaveStatuses] = useState({ result: [] });
  const [formVisible, setFormVisible] = useState(false);
  const [formVisible1, setFormVisible1] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const storedUserData = sessionStorage.getItem("user_data");
  const [currentWeekLeaves, setCurrentWeekLeaves] = useState([]);
  const [managerWiseEmployees, setManagerWiseEmployees] = useState([]);
  let usr_log = null;
  if (storedUserData) {
    usr_log = JSON.parse(storedUserData);
  }
  console.log(usr_log);
  console.log(data1);
  console.log(selectedApp);
  console.log(leaveStatuses);
  const formatDate = (dateString) => {
    if (!dateString) return "N/A"; // Return a default value if dateString is undefined or null
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };
  const [leavecnt, Setleavecnt] = useState({ login_lve_bal_cnt: [], lve_apl_dt: [], login_team_membr_inf_res: [], shw_upcom_hlod_res: [], empwise_lve_bal_res: [], all_usr_cnt_data: [], mngr_wise_emp_lst_res: [], aflt_annocement_news_result: [] })
  console.log(
    "leavecnt", leavecnt
  )
  useEffect(() => {
    const lev_bal_cnt = async () => {
      try {
        const lve_cnt = await services.get_lev_bal_cnt();

        if (lve_cnt) {
          Setleavecnt(lve_cnt); // Wrap it in an object if necessary
        } else {
          console.error("Unexpected data format:", lve_cnt);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    lev_bal_cnt();

  }, [])
  // Get the last 10 leave applications
  const last10Applications = leavecnt.lve_apl_dt
    .sort((a, b) => new Date(b.APP_DT) - new Date(a.APP_DT)) // Sort by application date descending
    .slice(0, 10); // Get the first 10 entries
  const fetchApplicantData = async () => {
    try {
      const result = await services.getapplicantdata();
      console.log("Fetched applicant data:", result); // Log the result for debugging
      if (result) {
        setData({ result: result.result || [] });
      } else {
        console.log("No data fetched", result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchLeaveStatuses = async () => {
    try {
      const name = "LVE_STTS"; // Example name you want to send
      const res = await services.getLeaveStatuses();
      console.log("Fetched leave statuses:", res);
      setLeaveStatuses(res);
    } catch (error) {
      console.error("Error fetching leave statuses:", error);
    }
  };
  useEffect(() => {
    const fetch = async () => {
      try {
        const lve_tp = await services.getAffiliateLeaveTypes();

        if (lve_tp) {
          setData1(lve_tp); // Wrap it in an object if necessary
        } else {
          console.error("Unexpected data format:", lve_tp);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    fetchApplicantData();
    fetchLeaveStatuses(); // Fetch leave statuses on component mount
  }, []);

  const handleOpen = (app) => {
    setSelectedApp(app);
    setApprovalComment("");
  };

  const handleClose = () => {
    setSelectedApp(null);
  };

  const getNextLeaveStatus = (currentStatus, action) => {
    console.log("Current status:", currentStatus); // Log the current status
    console.log("Action:", action); // Log the action (Approve or Reject)

    // Access leaveStatuses.result to ensure we are working with the array
    const statuses = leaveStatuses.result || []; // Fallback to an empty array if result is not available

    if (!Array.isArray(statuses) || statuses.length === 0) {
      console.warn("leaveStatuses is not an array or is empty:", leaveStatuses);
      return null;
    }

    console.log(
      "Leave statuses:",
      statuses.map((status) => status.ATTRIB_NAME)
    ); // Log leave status names

    // Define logic based on current status and action
    if (currentStatus === "Pending") {
      if (action === "Approve") {
        return statuses.find((status) => status.ATTRIB_NAME === "Approved");
      } else if (action === "Reject") {
        return statuses.find((status) => status.ATTRIB_NAME === "Rejected");
      }
    } else if (currentStatus === "Cancel for Approval") {
      if (action === "Approve") {
        return statuses.find(
          (status) => status.ATTRIB_NAME === "Cancel Approved"
        );
      } else if (action === "Reject") {
        return statuses.find(
          (status) => status.ATTRIB_NAME === "Cancel Rejected"
        );
      }
    }

    // Log if no matching logic is found
    console.log("No valid status change available for this action");
    return null; // Return null if no valid status is found
  };

  const handleApprove = async () => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const actualapprovalId = usr_log[0]?.USR_EMP_ID || "Unknown";
    const nextStatus = getNextLeaveStatus(selectedApp.APP_LVE_STTS, "Approve"); // Pass "Approve" as the action

    if (!nextStatus) {
      console.error("No next leave status available");
      alert("Allready Changed Leave Status");
      return;
    }

    const updatedApp = {
      ...selectedApp,
      APP_LVE_STTS: nextStatus.ATTRIB_NAME,
      LEAVE_STATUS_CHANGE_DATE: formattedDate,
      approvalComment: approvalComment,
      actualapprovalID: actualapprovalId,
      APP_LVE_STTS_DT: formattedDate,
      opn_lvl_bal_cnt: data1.result[0].LVE_TYP_OP_BAL_CNT,
    };

    try {
      await services.approveLeaveApplication(updatedApp);
      alert("Leave application approved successfully.");
      handleClose();
      fetchApplicantData();
    } catch (error) {
      console.error("Error approving application:", error);
      alert("Failed to approve leave application. Please try again.");
    }
  };

  const handleApproveDirectly = async (app) => {
    console.log("data comes from user selection", app)
    const selectedData = data.result.find((dt) => dt.APP_EMP_ID === app.APP_EMP_ID && dt.APP_ID === app.APP_ID);
    console.log("finded data", selectedData)
    console.log("selectedData.APP_LVE_STTS", selectedData.APP_LVE_STTS)
    if (selectedData) {
      setSelectedApp(selectedData);
      const currentDate = new Date();
      const formattedDate = currentDate
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      const actualapprovalId = usr_log[0]?.USR_EMP_ID || "Unknown";
      const nextStatus = getNextLeaveStatus(selectedData.APP_LVE_STTS, "Approve"); // Pass "Approve" as the action

      if (!nextStatus) {
        console.error("No next leave status available");
        alert("Allready Changed Leave Status");
        handleClose();
        return;
      }

      const updatedApp = {
        ...selectedData,
        APP_LVE_STTS: nextStatus.ATTRIB_NAME,
        LEAVE_STATUS_CHANGE_DATE: formattedDate,
        approvalComment: "",
        actualapprovalID: actualapprovalId,
        APP_LVE_STTS_DT: formattedDate,
        opn_lvl_bal_cnt: data1.result[0].LVE_TYP_OP_BAL_CNT,
        lve_bal_id: data1.result[0].LVE_BAL_ID,
        lve_ntt_id: data1.result[0].LVE_BAL_NTT_ID,
      };

      try {
        await services.approveLeaveApplication(updatedApp);
        alert("Leave application approved successfully.");
        handleClose();
        fetchApplicantData();
      } catch (error) {
        console.error("Error approving application:", error);
        alert("Failed to approve leave application. Please try again.");
      }
    } else {
      console.error("Selected data not found");
    }
  };

  const handleReject = async () => {
    handleOpen(selectedApp);
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const actualapprovalId = usr_log[0]?.USR_EMP_ID || "Unknown";
    const nextStatus = getNextLeaveStatus(selectedApp.APP_LVE_STTS, "Reject"); // Pass "Reject" as the action

    if (!nextStatus) {
      console.error("No next leave status available");
      alert("Allready Changed Leave Status");
      return;
    }

    const updatedApp = {
      ...selectedApp,
      APP_LVE_STTS: nextStatus.ATTRIB_NAME,
      LEAVE_STATUS_CHANGE_DATE: formattedDate,
      approvalComment: approvalComment,
      actualapprovalID: actualapprovalId,
      APP_LVE_STTS_DT: formattedDate,
      opn_lvl_bal_cnt: data1.result[0].LVE_TYP_OP_BAL_CNT,
    };

    try {
      await services.rejectLeaveApplication(updatedApp);
      alert("Leave application rejected successfully.");
      handleClose();
      fetchApplicantData();
    } catch (error) {
      console.error("Error rejecting application:", error);
      alert("Failed to reject leave application. Please try again.");
    }
  };

  const handleRejectdirectly = async (app) => {
    console.log("data comes from user selection", app)
    const selectedData = data.result.find((dt) => dt.APP_EMP_ID === app.APP_EMP_ID && dt.APP_ID === app.APP_ID);
    console.log("finded data", selectedData)
    console.log("selectedData.APP_LVE_STTS", selectedData.APP_LVE_STTS)
    if (selectedData) {
      setSelectedApp(selectedData);
      const currentDate = new Date();
      const formattedDate = currentDate
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      const actualapprovalId = usr_log[0]?.USR_EMP_ID || "Unknown";
      const nextStatus = getNextLeaveStatus(selectedData.APP_LVE_STTS, "Reject"); // Pass "Reject" as the action
      console.log("nextStatus", nextStatus)
      if (!nextStatus) {
        console.error("No next leave status available");
        alert("Allready Changed Leave Status");
        handleClose();
        return;
      }

      const updatedApp = {
        ...selectedData,
        APP_LVE_STTS: nextStatus.ATTRIB_NAME,
        LEAVE_STATUS_CHANGE_DATE: formattedDate,
        approvalComment: "",
        actualapprovalID: actualapprovalId,
        APP_LVE_STTS_DT: formattedDate,
        opn_lvl_bal_cnt: data1.result[0].LVE_TYP_OP_BAL_CNT,
      };

      try {
        await services.rejectLeaveApplication(updatedApp);
        alert("Leave application rejected successfully.");
        handleClose();
        fetchApplicantData();
      } catch (error) {
        console.error("Error rejecting application:", error);
        alert("Failed to reject leave application. Please try again.");
        handleClose();
      }
    } else {
      console.error("Selected data not found");
      handleClose();
    }
  };

  const handleCancel = async () => {
    try {
      await services.cancelLeaveApplication(selectedApp.APP_ID);
      handleClose();
      fetchApplicantData(); // Refresh data after cancellation
    } catch (error) {
      console.error("Error canceling application:", error);
    }
  };
  const toggleFormVisibility = () => {
    setFormVisible((prev) => !prev); // Toggle form visibility
  };
  const toggleFormVisibility1 = (resetSelectedRecord = false) => {
    if (resetSelectedRecord) {
      setSelectedRecord(null);
    }
    setFormVisible1((prev) => !prev); // Toggle form visibility
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    // Handle form submission logic
    toggleFormVisibility(); // Optionally hide the form after submission
  };

  const handleEdit = (id) => {
    // Logic for editing the record with the given id
    console.log("Edit record with ID:", id);
  };

  const handleDelete = (id) => {
    // Logic for deleting the record with the given id
    console.log("Delete record with ID:", id);
  };

  const handleView = (record) => {
    setSelectedRecord(record);
    toggleFormVisibility1(); // If this opens the view form/modal
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await services.getselecempdetails(selectedApp);
        if (res) {
          setEntity(res);
        }
      } catch (error) {
        console.error("Failed to fetch the data");
      }
    };
    fetch();
  }, [selectedApp]);
  console.log(entity)

  useEffect(() => {
    // Calculate current week's start and end dates
    const startOfWeek = moment().startOf('week').startOf('day'); // Start of the week
    const endOfWeek = moment().endOf('week').endOf('day'); // End of the week

    // Filter team members' leave applications for the current week
    const currentWeekLeaves = leavecnt.login_team_membr_inf_res.filter(leave => {
      const leaveStartDate = moment(leave.APP_LVE_FRM_DT); // Start date of the leave
      const leaveEndDate = moment(leave.APP_LVE_TO_DT); // End date of the leave
      console.log("Start of Week:", startOfWeek.format());
      console.log("End of Week:", endOfWeek.format());
      console.log("Leave Start Date:", leaveStartDate.format());
      console.log("Leave End Date:", leaveEndDate.format());
      // Check if the leave overlaps with the current week
      return (
        (leaveStartDate.isBetween(startOfWeek, endOfWeek, null, '[]') || // Starts during the week
          leaveEndDate.isBetween(startOfWeek, endOfWeek, null, '[]') || // Ends during the week
          (leaveStartDate.isBefore(startOfWeek) && leaveEndDate.isAfter(endOfWeek))) // Spans the entire week
      );
    });

    setCurrentWeekLeaves(currentWeekLeaves);
  }, [leavecnt.login_team_membr_inf_res]); // Re-run when login_team_membr_inf_res changes
  console.log("currentWeekLeaves", currentWeekLeaves)

  const processManagerWiseData = (data) => {
    const managerMap = {};

    data.forEach((employee) => {
      if (employee.role === 'Manager') {
        managerMap[employee.aprvlid] = {
          managerName: employee.display_name,
          employees: []
        };
      } else if (employee.role === 'Employee') {
        const managerId = employee.aprvlid; // Assuming aprvlid is the manager's ID
        if (managerMap[managerId]) {
          managerMap[managerId].employees.push({
            name: employee.display_name,
            empId: employee.employee_id,
          });
        }
      }
    });

    return Object.values(managerMap);
  };
  useEffect(() => {
    const managerWiseEmployees = processManagerWiseData(leavecnt.mngr_wise_emp_lst_res);
    setManagerWiseEmployees(managerWiseEmployees);
  }, [leavecnt.mngr_wise_emp_lst_res]); // Run when leavecnt updates
  // Call this function to get the processed data
  console.log("managerWiseEmployees", managerWiseEmployees)


  //for Annocements    
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState('');
  const [isPaused, setIsPaused] = useState(false);


  const handleMouseEnter = () => {
    setIsPaused(true); // Pause the marquee
  };

  const handleMouseLeave = () => {
    setIsPaused(false); // Resume the marquee
  };

  const handleAnnouncementClick = (announcement) => {
    setSelectedAnnouncement(announcement);
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setSelectedAnnouncement('');
  };
  console.log("selectedAnnouncement", selectedAnnouncement)

  const marqueeItems = leavecnt.aflt_annocement_news_result.map((app, index) => (
    <span key={app.LVE_NEWS_KEY} // Assuming LVE_NEWS_KEY is unique
      onClick={() => handleAnnouncementClick(app)} // Assuming app holds the full announcement text
      style={{ cursor: 'pointer', marginRight: '20px' }} // Add pointer cursor and margin
    >
      {app.LVE_NTC_HEAD_SUB}
      {index < leavecnt.aflt_annocement_news_result.length - 1 && <span className="pipe">|</span>} {/* Add pipe with CSS class */}
    </span>
  ));
  return (
    <>
      <div
        className={`marquee ${isPaused ? 'paused' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="marquee-content">{marqueeItems}</div>
      </div>

      {/* Popup for Announcement */}
      <Dialog open={popupOpen} onClose={handleClosePopup} maxWidth="lg" fullWidth>
        <DialogTitle className="dialog-title">
          Announcement Details
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClosePopup}
            aria-label="close"
            className="close-button"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Typography className="announcement-heading">
            Announcement Heading :- <b>{selectedAnnouncement.LVE_NTC_HEAD_SUB}</b>
          </Typography>
          <Typography className="announcement-text">
            Actual Announcement :- <b>{selectedAnnouncement.LVE_ACTUL_NTC}</b>
          </Typography>
          {/* Add more fields as needed */}
        </DialogContent>
      </Dialog>


      <div className="wrapper_form">
        <div className="title mgadmindash">Pending Cancellation Application List</div>
        {selectedApp ? (
          <div className="container">
            <Typography variant="h6" className="pd11">Application Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Employee ID"
                  value={selectedApp?.APP_EMP_ID || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="OBO ID"
                  value={selectedApp?.APP_OBO_ID || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Approval ID"
                  value={selectedApp?.APP_APRVL_ID || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="CC ID"
                  value={selectedApp?.APP_CC_TO_ID || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Date Submitted"
                  value={
                    selectedApp?.APP_DT
                      ? new Date(selectedApp.APP_DT).toLocaleDateString()
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Status"
                  value={selectedApp?.APP_LVE_STTS || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Leave Type"
                  value={selectedApp?.APP_TYP || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Leave Balance count"
                  value={selectedApp?.APP_LVE_BAL || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Leave From"
                  value={selectedApp?.APP_LVE_FRM_DT || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Leave To"
                  value={selectedApp?.APP_LVE_TO_DT || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Half Day ?"
                  value={selectedApp?.APP_HLF_DE || "N"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="1st or 2nd Half"
                  value={selectedApp?.APP_HLF_1_2 || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Conjugative Half Day ?"
                  value={selectedApp?.APP_CNZTV_HLF_DE_YN || "N"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Daily Half Day ?"
                  value={selectedApp?.APP_DLY_HLF_DE_YN || "N"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Half Day From"
                  value={selectedApp?.APP_HLF_FRM_DT || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Half Day TO"
                  value={selectedApp?.APP_HLF_TO_DT || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Leave Reason"
                  value={selectedApp?.APP_LVE_RES || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Address"
                  value={selectedApp?.APP_LVE_ADD || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone"
                  value={selectedApp?.APP_PH_NO || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Approval Remarks"
                  value={approvalComment}
                  onChange={(e) => setApprovalComment(e.target.value)}
                  variant="filled"
                  multiline
                // rows={2}
                />
              </Grid>
            </Grid>
            <div className="button-container">
              <Button
                className="action-button"
                style={{ width: "10%", marginRight: "15px" }}
                onClick={handleApprove}
              >
                Approve
              </Button>
              <Button
                className="action-button"
                style={{ width: "10%", marginRight: "15px" }}
                onClick={handleReject}
              >
                Reject
              </Button>
              <Button
                className="action-button"
                style={{ width: "10%", marginRight: "15px" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>

        ) : (
          <div>
            <div className="grid-item">
              <TableContainer className="table-container">
                <Table className="table">
                  <TableHead>
                    <TableRow className="table-row">
                      <TableCell className="header-cell">Name</TableCell>
                      <TableCell className="header-cell">Employee ID</TableCell>
                      <TableCell className="header-cell">OBO ID</TableCell>
                      <TableCell className="header-cell">Date Submitted</TableCell>
                      <TableCell className="header-cell">Leave Type</TableCell>
                      <TableCell className="header-cell">Leave From</TableCell>
                      <TableCell className="header-cell">Leave To</TableCell>
                      <TableCell className="header-cell">Status</TableCell>
                      <TableCell className="header-cell">Actions</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={9} style={{ padding: 0 }} />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.result
                      .filter(
                        (app) =>
                          app.APP_LVE_STTS === "Pending" ||
                          app.APP_LVE_STTS === "Cancel for Approval"
                      )
                      .map((app, index) => (
                        <React.Fragment key={index}>
                          <TableRow className="table-row">
                            <TableCell className="cell">{app.USR_DISPLAY_NAME}</TableCell>
                            <TableCell className="cell">{app.APP_EMP_ID}</TableCell>
                            <TableCell className="cell">{app.APP_OBO_ID}</TableCell>
                            <TableCell className="cell">
                              {app.APP_DT ? formatDate1(app.APP_DT) : "empty"}
                            </TableCell>
                            <TableCell className="cell">{app.APP_TYP}</TableCell>
                            <TableCell className="cell">
                              {app.APP_LVE_FRM_DT ? formatDate1(app.APP_LVE_FRM_DT) : "empty"}
                            </TableCell>
                            <TableCell className="cell">
                              {app.APP_LVE_TO_DT ? formatDate1(app.APP_LVE_TO_DT) : "empty"}
                            </TableCell>
                            <TableCell className="cell">{app.APP_LVE_STTS}</TableCell>

                            <TableCell className="cell centered-cell">
                              <Button
                                style={{ minWidth: "auto" }}
                                onClick={() => handleApproveDirectly(app)}
                                size="small"
                              >
                                <CheckIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                              </Button>
                              <Button
                                style={{ minWidth: "auto" }}
                                onClick={() => handleRejectdirectly(app)}
                                size="small"
                              >
                                <CancelIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                              </Button>

                              <Button
                                style={{ minWidth: "auto" }}
                                onClick={() => handleOpen(app)}
                                size="small"
                              >
                                <VisibilityIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                              </Button>
                            </TableCell>

                          </TableRow>
                        </React.Fragment>
                      ))}

                    {data.result.length < 5 &&
                      Array.from({ length: 5 - data.result.length }).map((_, index) => (
                        <TableRow key={`empty-row-${index}`} className="empty-row">
                          <TableCell className="cell">&nbsp;</TableCell>
                          <TableCell className="cell">&nbsp;</TableCell>
                          <TableCell className="cell">&nbsp;</TableCell>
                          <TableCell className="cell">&nbsp;</TableCell>
                          <TableCell className="cell">&nbsp;</TableCell>
                          <TableCell className="cell">&nbsp;</TableCell>
                          <TableCell className="cell">&nbsp;</TableCell>
                          <TableCell className="cell">&nbsp;</TableCell>
                          <TableCell className="cell">&nbsp;</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <hr className="hr-style" />
            </div>

            <div>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6} className="grid-item">
                  <div className="title title-center">Upcoming Holidays</div>
                  <TableContainer component={Paper} className="table-container">
                    <Table className="table">
                      <TableHead>
                        <TableRow className="table-row">
                          <TableCell className="header-cell">Sr. No.</TableCell>
                          <TableCell className="header-cell">Date</TableCell>
                          <TableCell className="header-cell">Day</TableCell>
                          <TableCell className="header-cell">Particulars / Occasion</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leavecnt.shw_upcom_hlod_res
                          .sort((a, b) => new Date(a.HOLIDAY_DATE) - new Date(b.HOLIDAY_DATE))
                          .map((leave, index) => (
                            <TableRow className="table-row" key={leave.leaveTypeId}>
                              <TableCell className="cell">{index + 1}</TableCell>
                              <TableCell className="cell">{formatDate(leave.HOLIDAY_DATE)}</TableCell>
                              <TableCell className="cell">{moment(leave.HOLIDAY_DATE).format('dddd')}</TableCell>
                              <TableCell className="cell">{leave.HOLIDAY_TYP}</TableCell>
                            </TableRow>
                          ))}
                        {leavecnt.shw_upcom_hlod_res.length < 10 &&
                          Array.from({ length: 10 - leavecnt.shw_upcom_hlod_res.length }).map((_, index) => (
                            <TableRow className="table-row empty-row" key={`empty-row-${index}`}>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={6} className="grid-item">
                  <div className="title title-center">Current Week Team Member On Leave</div>
                  <TableContainer component={Paper} className="table-container-leave-type">
                    <Table className="table">
                      <TableHead>
                        <TableRow className="table-row">
                          <TableCell className="header-cell">Name</TableCell>
                          <TableCell className="header-cell">EMP ID</TableCell>
                          <TableCell className="header-cell">From</TableCell>
                          <TableCell className="header-cell">To</TableCell>
                          <TableCell className="header-cell">Duration</TableCell>
                          <TableCell className="header-cell">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentWeekLeaves.map((leave) => (
                          <TableRow className="table-row" key={leave.leaveTypeId}>
                            <TableCell className="cell">{leave.USR_DISPLAY_NAME}</TableCell>
                            <TableCell className="cell">{leave.APP_EMP_ID}</TableCell>
                            <TableCell className="cell">{formatDate1(leave.APP_LVE_FRM_DT)}</TableCell>
                            <TableCell className="cell">{formatDate1(leave.APP_LVE_TO_DT)}</TableCell>
                            <TableCell className="cell">{leave.APP_LVE_DES} days</TableCell>
                            <TableCell className="cell">{leave.APP_LVE_STTS}</TableCell>
                          </TableRow>
                        ))}
                        {currentWeekLeaves.length < 10 &&
                          Array.from({ length: 10 - currentWeekLeaves.length }).map((_, index) => (
                            <TableRow className="table-row empty-row" key={`empty-row-${index}`}>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <hr className="hr-style" />

              <Grid container spacing={2} justifyContent="center">

                {/* Left Table */}
                <Grid item xs={6} className="grid-item">
                  <div className="title title-center">Leave Balance Details</div>
                  <TableContainer component={Paper} className="table-container">
                    <Table className="table">
                      <TableHead>
                        <TableRow className="table-row">
                          <TableCell className="header-cell">Leave Type</TableCell>
                          <TableCell className="header-cell">Op. Bal.</TableCell>
                          <TableCell className="header-cell">Added in Year</TableCell>
                          <TableCell className="header-cell">Consume</TableCell>
                          <TableCell className="header-cell">Balance</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leavecnt.login_lve_bal_cnt.slice(0, 10).map((leave) => (
                          <TableRow className="table-row" key={leave.leaveTypeId}>
                            <TableCell className="cell">{leave.disp_nm}</TableCell>
                            <TableCell className="cell">{leave.LVE_TYP_OP_BAL_CNT}</TableCell>
                            <TableCell className="cell">&nbsp;</TableCell>
                            <TableCell className="cell">{leave.LVE_TYP_CNMSN_CNT}</TableCell>
                            <TableCell className="cell">{leave.LVE_TYP_RN_BAL_CNT}</TableCell>
                          </TableRow>
                        ))}
                        {/* Add empty rows if there are less than 10 rows */}
                        {leavecnt.login_lve_bal_cnt.length < 10 &&
                          Array.from({ length: 10 - leavecnt.login_lve_bal_cnt.length }).map((_, index) => (
                            <TableRow className="table-row empty-row" key={`empty-row-${index}`}>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                {/* Right Table */}
                <Grid item xs={6} className="grid-item">
                  <div className="title title-center">Last 10 Leave Application Status</div>
                  <TableContainer component={Paper} className="table-container-leave-type">
                    <Table className="table">
                      <TableHead>
                        <TableRow className="table-row">
                          <TableCell className="header-cell">Date</TableCell>
                          <TableCell className="header-cell">From</TableCell>
                          <TableCell className="header-cell">To</TableCell>
                          <TableCell className="header-cell">Duration</TableCell>
                          <TableCell className="header-cell">Type</TableCell>
                          <TableCell className="header-cell">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {last10Applications.slice(0, 10).map((aplicantdata) => (
                          <TableRow className="table-row" key={aplicantdata.leaveTypeId}>
                            <TableCell className="cell">{formatDate(aplicantdata.APP_DT)}</TableCell>
                            <TableCell className="cell">{formatDate(aplicantdata.APP_LVE_FRM_DT)}</TableCell>
                            <TableCell className="cell">{formatDate(aplicantdata.APP_LVE_TO_DT)}</TableCell>
                            <TableCell className="cell">{aplicantdata.APP_LVE_DES} days</TableCell>
                            <TableCell className="cell">{aplicantdata.disp_nm}</TableCell>
                            <TableCell className="cell">{aplicantdata.APP_LVE_STTS}</TableCell>
                          </TableRow>
                        ))}
                        {last10Applications.length < 10 &&
                          Array.from({ length: 10 - last10Applications.length }).map((_, index) => (
                            <TableRow className="table-row empty-row" key={`empty-row-${index}`}>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                              <TableCell className="cell">&nbsp;</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <hr className="hr-style" />

              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6} className="grid-item">
                  <div className="title">Employee-wise Balances</div>
                  <TableContainer component={Paper} className="table-container" style={{ maxHeight: '400px', overflow: 'auto' }}>
                    <Table stickyHeader>
                      {/* Table Header */}
                      <TableHead>
                        <TableRow className="table-row">
                          <TableCell className="header-cell">Sr. No.</TableCell>
                          <TableCell className="header-cell">Employee ID</TableCell>
                          <TableCell className="header-cell">Name</TableCell>
                          <TableCell className="header-cell">Leave Type</TableCell>
                          <TableCell className="header-cell">Yearly Consume</TableCell>
                          <TableCell className="header-cell">Leave Balance</TableCell>
                        </TableRow>
                      </TableHead>

                      {/* Table Body */}
                      <TableBody>
                        {Object.values(
                          leavecnt.empwise_lve_bal_res.reduce((acc, current) => {
                            const employeeId = current.USR_EMP_ID;
                            const employeeName = current.USR_DISPLAY_NAME;
                            const leaveType = current.disp_nm;
                            const conjumbal = current.LVE_TYP_CNMSN_CNT;
                            const remainingBalance = current.LVE_TYP_RN_BAL_CNT;

                            if (!acc[employeeId]) {
                              acc[employeeId] = {
                                employeeId,
                                employeeName,
                                leaveTypes: [],
                              };
                            }

                            acc[employeeId].leaveTypes.push({
                              leaveType,
                              conjumbal,
                              remainingBalance,
                            });

                            return acc;
                          }, {})).map((employee, index) => (
                            <React.Fragment key={index}>
                              <TableRow className="table-row">
                                <TableCell align="center" className="cell">{index + 1}</TableCell>
                                <TableCell align="center" className="cell">{employee.employeeId}</TableCell>
                                <TableCell align="center" className="cell">{employee.employeeName}</TableCell>
                                <TableCell align="center" className="cell">&nbsp;</TableCell>
                                <TableCell align="center" className="cell">&nbsp;</TableCell>
                                <TableCell align="center" className="cell">&nbsp;</TableCell>
                              </TableRow>
                              {employee.leaveTypes.map((leave, index) => (
                                <TableRow className="table-row" key={index}>
                                  <TableCell align="center" className="cell">&nbsp;</TableCell>
                                  <TableCell align="center" className="cell">&nbsp;</TableCell>
                                  <TableCell align="center" className="cell">&nbsp;</TableCell>
                                  {/* <TableCell align="center" className="cell">&nbsp;</TableCell> */}
                                  <TableCell align="center" className="cell">{leave.leaveType}</TableCell>
                                  <TableCell align="center" className="cell">{leave.conjumbal}</TableCell>
                                  <TableCell align="center" className="cell">{leave.remainingBalance}</TableCell>
                                </TableRow>
                              ))}
                            </React.Fragment>
                          ))}
                        {/* Add empty rows if there are less than 8 rows */}
                        {leavecnt.empwise_lve_bal_res.length < 8 &&
                          Array.from({ length: 8 - leavecnt.empwise_lve_bal_res.length }).map((_, index) => (
                            <TableRow className="table-row empty-row" key={`empty-row-${index}`}>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={6} className="grid-item">
                  <div className="title">Max Leave Consumption Employees</div>
                  <TableContainer component={Paper} className="table-container-leave-type" style={{ maxHeight: '400px', overflow: 'auto' }}>
                    <Table stickyHeader>
                      {/* Table Header */}
                      <TableHead>
                        <TableRow className="table-row">
                          <TableCell className="header-cell">Sr.No</TableCell>
                          <TableCell className="header-cell">Leave Type</TableCell>
                          <TableCell className="header-cell">Employee Name</TableCell>
                          <TableCell className="header-cell">Consumed Balance</TableCell>
                        </TableRow>
                      </TableHead>

                      {/* Table Body */}
                      <TableBody>
                        {(() => {
                          const leaveTypeGroups = leavecnt.empwise_lve_bal_res.reduce((acc, leave) => {
                            if (!acc[leave.disp_nm]) {
                              acc[leave.disp_nm] = {
                                leaveType: leave.disp_nm,
                                employees: [],
                              };
                            }
                            acc[leave.disp_nm].employees.push({
                              name: leave.USR_DISPLAY_NAME,
                              consumed: leave.LVE_TYP_CNMSN_CNT,
                            });
                            return acc;
                          }, {});

                          const result = Object.values(leaveTypeGroups).map(group => {
                            const topEmployees = group.employees
                              .sort((a, b) => b.consumed - a.consumed)
                              .slice(0, 5);

                            return {
                              leaveType: group.leaveType,
                              topEmployees: topEmployees,
                            };
                          });

                          let srNo = 1;
                          const rows = result.flatMap((item) =>
                            item.topEmployees.map((emp, index) => (
                              <TableRow className="table-row" key={`${item.leaveType}-${emp.name}`}>
                                {index === 0 && (
                                  <>
                                    <TableCell rowSpan={item.topEmployees.length} align="center" className="cell">{srNo++}</TableCell>
                                    <TableCell rowSpan={item.topEmployees.length} align="center" className="cell">{item.leaveType}</TableCell>
                                  </>
                                )}
                                <TableCell align="center" className="cell">{emp.name}</TableCell>
                                <TableCell align="center" className="cell">{emp.consumed}</TableCell>
                              </TableRow>
                            ))
                          );

                          // Determine how many empty rows are needed
                          const emptyRows = 10 - rows.length; // Targeting a total of 10 rows
                          const emptyRowElements = Array.from({ length: Math.max(0, emptyRows) }).map((_, index) => (
                            <TableRow className="table-row empty-row" key={`empty-row-${index}`}>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                            </TableRow>
                          ));

                          return [...rows, ...emptyRowElements]; // Combine data rows with empty rows
                        })()}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <hr className="hr-style" />


              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6} className="grid-item">
                  <div className="title" style={{ marginTop: "2%" }}>All User Details</div>
                  <TableContainer
                    component={Paper}
                    className="table-container"
                  >
                    <Table>
                      <TableHead>
                        <TableRow className="table-row">
                          <TableCell className="header-cell">Sr.No</TableCell>
                          <TableCell className="header-cell">Name</TableCell>
                          <TableCell className="header-cell">Emp ID</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leavecnt.all_usr_cnt_data.map((aplicantdata, index) => (
                          <TableRow key={aplicantdata.leaveTypeId} className="table-row">
                            <TableCell align="center" className="cell">{index + 1}</TableCell>
                            <TableCell align="center" className="cell">{aplicantdata.USR_DISPLAY_NAME}</TableCell>
                            <TableCell align="center" className="cell">{aplicantdata.USR_EMP_ID}</TableCell>
                          </TableRow>
                        ))}
                        {leavecnt.all_usr_cnt_data.length < 10 &&
                          Array.from({ length: 10 - leavecnt.all_usr_cnt_data.length }).map((_, index) => (
                            <TableRow key={`empty-row-${index}`} className="table-row empty-row">
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={6} className="grid-item">
                  <div className="title" style={{ marginTop: "2%" }}>Manager Wise Employees</div>
                  <TableContainer
                    component={Paper}
                    className="table-container"
                  >
                    <Table>
                      <TableHead>
                        <TableRow className="table-row">
                          <TableCell className="header-cell">Sr. No</TableCell>
                          <TableCell className="header-cell">Manager</TableCell>
                          <TableCell className="header-cell">Employee Name</TableCell>
                          <TableCell className="header-cell">Emp ID</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {managerWiseEmployees.map((manager, managerIndex) => (
                          <>
                            {/* Render the manager's row even if they have no employees */}
                            <TableRow key={manager.managerName} className="table-row">
                              <TableCell align="center" className="cell">{managerIndex + 1}</TableCell>
                              <TableCell align="center" className="cell">{manager.managerName}</TableCell>
                              {/* Check if there are employees and display accordingly */}
                              {manager.employees.length > 0 ? (
                                <TableCell align="center" className="cell">{manager.employees[0].name}</TableCell>
                              ) : (
                                <TableCell align="center" className="cell">No Employees</TableCell>
                              )}
                              {manager.employees.length > 0 ? (
                                <TableCell align="center" className="cell">{manager.employees[0].empId}</TableCell>
                              ) : (
                                <TableCell align="center" className="cell">N/A</TableCell>
                              )}
                            </TableRow>

                            {/* Render additional employees if they exist */}
                            {manager.employees.slice(1).map((emp, empIndex) => (
                              <TableRow key={emp.empId} className="table-row">
                                <TableCell align="center" className="cell">&nbsp;</TableCell>
                                <TableCell align="center" className="cell">&nbsp;</TableCell>
                                <TableCell align="center" className="cell">{emp.name}</TableCell>
                                <TableCell align="center" className="cell">{emp.empId}</TableCell>
                              </TableRow>
                            ))}
                          </>
                        ))}
                        {/* Adding empty rows for the second table */}
                        {managerWiseEmployees.length < 15 &&
                          Array.from({ length: 15 - managerWiseEmployees.length }).map((_, index) => (
                            <TableRow key={`empty-row-manager-${index}`} className="table-row empty-row">
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                              <TableCell align="center" className="cell">&nbsp;</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

            </div>
          </div>
        )}
      </div>
    </>
  );
}
