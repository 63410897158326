import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
} from "@mui/material";
import moment from 'moment';
export default function Manager_Agine_Report_Of_Lve() {
    const [agingrpt, setagingrpt] = useState({ agine_rpt_mng_res: [] });
    useEffect(() => {
        const emp_aging_cnt = async () => {
            try {
                const res = await services.get_aging_dt_mn();

                if (res) {
                    setagingrpt(res)
                    console.log("Aging Data fetched succesfully of team members for manager")
                }
                else {
                    console.log("Failed to fetch data of  Aging report for manager")
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        emp_aging_cnt();
    }, [])
    console.log("agingrpt", agingrpt)
    function formatDate(dateString) {
        // Format the date to 'DD/MMM/YYYY' with the first letter of the month capitalized
        return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
    }
    // Calculate totals
    const totals = agingrpt.agine_rpt_mng_res.reduce((acc, applicantdata) => {
        acc.Less_Than_30_Days += applicantdata.Less_Than_30_Days || 0;
        acc.Days_31_To_60 += applicantdata.Days_31_To_60 || 0;
        acc.Days_61_To_90 += applicantdata.Days_61_To_90 || 0;
        acc.More_Than_90_Days += applicantdata.More_Than_90_Days || 0;
        return acc;
    }, {
        Less_Than_30_Days: 0,
        Days_31_To_60: 0,
        Days_61_To_90: 0,
        More_Than_90_Days: 0
    });
    return (
        <div className="wrapper_form">
            <Grid item xs={6} className="grid-item">
                <div className="title">Team Members Aging Analyasis Details</div>
                <TableContainer component={Paper} className="table-container-leave-type">
                    <Table className="table">
                        <TableHead>
                            <TableRow className="table-row">
                                <TableCell className="header-cell">Sr.No.</TableCell>
                                <TableCell className="header-cell">Emp. ID</TableCell>
                                <TableCell className="header-cell">Name</TableCell>
                                <TableCell className="header-cell">Application Date</TableCell>
                                <TableCell className="header-cell">Leave Type</TableCell>
                                <TableCell className="header-cell">Upto 30 Days</TableCell>
                                <TableCell className="header-cell">31 To 60 Days</TableCell>
                                <TableCell className="header-cell">61 To 90 Days</TableCell>
                                <TableCell className="header-cell">More than 90 Days</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {agingrpt.agine_rpt_mng_res.map((aplicantdata, index) => (
                                <TableRow key={aplicantdata.leaveTypeId}>
                                    <TableCell align="center" className="cell">{index + 1}</TableCell>
                                    <TableCell align="center" className="cell">{aplicantdata.APP_EMP_ID}</TableCell>
                                    <TableCell align="center" className="cell">{aplicantdata.USR_DISPLAY_NAME}</TableCell>
                                    <TableCell align="center" className="cell">{formatDate(aplicantdata.APP_DT)}</TableCell>
                                    <TableCell align="center" className="cell">{aplicantdata.disp_nm}</TableCell>
                                    <TableCell align="center" className="cell">{aplicantdata.Less_Than_30_Days}</TableCell>
                                    <TableCell align="center" className="cell">{aplicantdata.Days_31_To_60}</TableCell>
                                    <TableCell align="center" className="cell">{aplicantdata.Days_61_To_90}</TableCell>
                                    <TableCell align="center" className="cell">{aplicantdata.More_Than_90_Days}</TableCell>
                                </TableRow>
                            ))}
                            {agingrpt.agine_rpt_mng_res.length < 10 &&
                                Array.from({ length: 10 - agingrpt.agine_rpt_mng_res.length }).map((_, index) => (
                                    <TableRow key={`empty-row-manager-${index}`}>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                    </TableRow>
                                ))}
                            <TableRow className="total-row">
                                <TableCell align="center" className="cell">&nbsp;</TableCell>
                                <TableCell align="center" className="cell">Total</TableCell>
                                <TableCell align="center" className="cell">&nbsp;</TableCell>
                                <TableCell align="center" className="cell">&nbsp;</TableCell>
                                <TableCell align="center" className="cell">&nbsp;</TableCell>
                                <TableCell align="center" className="cell">{totals.Less_Than_30_Days}</TableCell>
                                <TableCell align="center" className="cell">{totals.Days_31_To_60}</TableCell>
                                <TableCell align="center" className="cell">{totals.Days_61_To_90}</TableCell>
                                <TableCell align="center" className="cell">{totals.More_Than_90_Days}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    )
}
