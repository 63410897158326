import React from 'react'
import { Link } from 'react-router-dom';
export default function NavbarLogin() {
  return (
    <>
      <header class="fixedheader">
        <div class="container_12">
          <div class="grid_12">
            <div class="wrapper"> <Link class="logo"></Link>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
