import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  IconButton
} from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default function Cust_Rep_Lve_App_Print() {

  const [leavecnt, Setleavecnt] = useState({
    login_lve_bal_cnt: [], lve_apl_dt: [], login_team_membr_inf_res: [],
    shw_upcom_hlod_res: [], empwise_lve_bal_res: [], all_usr_cnt_data: [], aflt_annocement_news_result: []
  })

  useEffect(() => {
    const lev_bal_cnt = async () => {
      try {
        const lve_cnt = await services.get_lev_bal_cnt();

        if (lve_cnt) {
          Setleavecnt(lve_cnt); // Wrap it in an object if necessary
        } else {
          console.error("Unexpected data format:", lve_cnt);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    lev_bal_cnt();

  }, [])
  function formatDate(dateString) {
    // Format the date to 'DD/MMM/YYYY' with the first letter of the month capitalized
    return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
  }
  console.log("leavecnt", leavecnt)

  const handleDownload = async (aplicantdata) => {
    try {
      console.log("hello from handleDownload");

      // Fetch the PDF from the server
      const response = await fetch('rdbs/dash_cust/generate-pdf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(aplicantdata), // Send the applicant data to the backend
      });

      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(url, '_blank');
      } else {
        console.error('Error generating PDF:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  return (
    <div className="wrapper_form">
      <Grid item xs={6} className="grid-item">
        <div className="title">Leave Applications</div>
        <TableContainer component={Paper} className="table-container-leave-type">
          <Table className="table">
            <TableHead>
              <TableRow className="table-row">
                <TableCell className="header-cell">Sr.No.</TableCell>
                <TableCell className="header-cell">Date</TableCell>
                <TableCell className="header-cell">From</TableCell>
                <TableCell className="header-cell">To</TableCell>
                <TableCell className="header-cell">Duration</TableCell>
                <TableCell className="header-cell">Type</TableCell>
                <TableCell className="header-cell">Status</TableCell>
                <TableCell className="header-cell">Print</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leavecnt.lve_apl_dt.map((aplicantdata, index) => (
                <TableRow className="table-row" key={aplicantdata.leaveTypeId}>
                  <TableCell align="center" className="cell">{index + 1}</TableCell>
                  <TableCell align="center" className="cell">{formatDate(aplicantdata.APP_DT)}</TableCell>
                  <TableCell align="center" className="cell">{formatDate(aplicantdata.APP_LVE_FRM_DT)}</TableCell>
                  <TableCell align="center" className="cell">{formatDate(aplicantdata.APP_LVE_TO_DT)}</TableCell>
                  <TableCell align="center" className="cell">{aplicantdata.APP_LVE_DES} days</TableCell>
                  <TableCell align="center" className="cell">{aplicantdata.disp_nm}</TableCell>
                  <TableCell align="center" className="cell">{aplicantdata.APP_LVE_STTS}</TableCell>
                  <TableCell align="center" className="cell">
                    <IconButton className="icon-button" onClick={() => handleDownload(aplicantdata)}>
                      <DownloadIcon className="download-icon" fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {leavecnt.lve_apl_dt.length < 10 &&
                Array.from({ length: 10 - leavecnt.lve_apl_dt.length }).map((_, index) => (
                  <TableRow key={`empty-row-manager-${index}`}>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                    <TableCell align="center" className="cell">&nbsp;</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  )
}
