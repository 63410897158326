import React, { useState } from "react";
import services from "./apiServices";

export default function ChangePassword() {
   const [errors, setErrors] = useState({});
   const [formData, setFormData] = useState({
      usr_name: "",
      old_pass: "",
      new_pass: "",
      conf_pass: "",
      same_pass: "", // Store error for password mismatch here
   });

   // Handle change in confirm password field
   const handleConfirmPasswordChange = (e) => {
      const { value } = e.target;
      setFormData({ ...formData, conf_pass: value });

      // Check if the confirm password matches the new password
      if (!value) {
         setErrors({
            ...errors,
            conf_pass: "Confirm Password is required.",
         });
      } else if (formData.new_pass && value !== formData.new_pass) {
         setErrors({
            ...errors,
            conf_pass: "required",
            same_pass: "Passwords do not match.", // Set this error if passwords don't match
         });
      } else {
         // Clear both error messages if they match
         setErrors({
            ...errors,
            conf_pass: "required",
            same_pass: "",
         });
      }
   };

   const validateForm = () => {
      const newErrors = {};

      // Check each field for emptiness
      if (!formData.usr_name) newErrors.usr_name = "required.";
      if (!formData.old_pass) newErrors.old_pass = "required.";
      if (!formData.new_pass) newErrors.new_pass = "required.";
      if (!formData.conf_pass) newErrors.conf_pass = "required.";

      setErrors(newErrors);
      return Object.keys(newErrors).length === 0; // Return true if no errors
   };

   const change_pass = async () => {
      if (validateForm()) {
         try {
            // Await the changepassword function
            const res = await services.changepassword(formData);

            if (res) {
               console.log("res", res);
               alert("Password Changed Successfully");
               setFormData({
                  usr_name: "",
                  old_pass: "",
                  new_pass: "",
                  conf_pass: "",
                  same_pass: "",
               });
            } else {
               alert("Login User Credentials are Incorrect.");
            }
         } catch (error) {
            // Log the error for debugging and show an alert to the user
            console.error("Error changing password:", error);
            alert("Failed to change the Password: " + (error.message || "Unknown error"));
         }
      } else {
         alert("Please fix the form validation errors before submitting.");
      }
   };

   return (
      <>
         <div className="wrapper_form wd_log" style={{ paddingLeft: "0px" }}>
            <div className="title" style={{ marginTop: "0px" }}>Change Password</div>
            <form method="Post">
               <br />
               <div className="field">
                  <input
                     type="text"
                     name="usr_name"
                     value={formData.usr_name || ""}
                     placeholder="Enter Login ID"
                     onChange={(e) =>
                        setFormData({ ...formData, usr_name: e.target.value })
                     }
                  />
                  <label>
                     Login ID{" "}
                     {errors.usr_name && (
                        <span className="error-message">{errors.usr_name}</span>
                     )}
                  </label>
               </div>
               <br />
               <div className="field">
                  <input
                     type="password"
                     name="old_pass"
                     value={formData.old_pass || ""}
                     placeholder="Enter Old Password"
                     onChange={(e) =>
                        setFormData({ ...formData, old_pass: e.target.value })
                     }
                  />
                  <label>
                     Old Password{" "}
                     {errors.old_pass && (
                        <span className="error-message">{errors.old_pass}</span>
                     )}
                  </label>
               </div>
               <br />

               <div className="field">
                  <input
                     type="password"
                     name="new_pass"
                     placeholder="Enter New Password"
                     value={formData.new_pass || ""}
                     onChange={(e) =>
                        setFormData({ ...formData, new_pass: e.target.value })
                     }
                  />
                  <label>
                     New Password{" "}
                     {errors.new_pass && (
                        <span className="error-message">{errors.new_pass}</span>
                     )}
                  </label>
               </div>
               <br />

               <div className="field">
                  <input
                     type="password"
                     name="conf_pass"
                     placeholder="Enter Confirm Password"
                     value={formData.conf_pass || ""}
                     onChange={handleConfirmPasswordChange} // Trigger validation here
                  />
                  <label>
                     Confirm Password{" "} {errors.conf_pass && (
                        <span className="error-message">{errors.conf_pass}</span>
                     )}
                  </label>

                  {errors.same_pass && (
                     <div className="centered-error">
                        <span className="error-message">{errors.same_pass}</span>
                     </div>
                  )}

               </div>

               <div className="field">
                  <button
                     style={{ marginTop: "15%" }}
                     type="button"
                     className="btn-action action-button btnfulwd"
                     onClick={change_pass}
                  >
                     Change Password
                  </button>
               </div>
            </form>
         </div>
      </>
   );
}
