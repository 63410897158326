import React, { useState, useEffect } from "react";
import services from "./apiServices";
import { List, ListItem, ListItemText, IconButton } from "@mui/material";
import { InsertDriveFile, Description } from '@mui/icons-material'; // Import the new icon

export default function FileDetails() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFileDetails = async () => {
      try {
        console.log("Fetching file details...");
        const res = await services.get_lve_policy_details();
        console.log("Response received:", res);
        if (res.files) {
          setFiles(res.files);
          console.log("Files state updated:", res.files);
        } else {
          console.error("Unexpected data format:", res);
        }
      } catch (error) {
        console.error("Error fetching file details:", error);
      }
    };
    fetchFileDetails();
  }, []);

  const openFile = (filePath) => {
    const newWindow = window.open(filePath, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null; // Prevent the new window from accessing the opener
  };

  // Select the last item (file) from the files array, if any
  const lastFile = files.length > 0 ? files[files.length - 1] : null;

  return (
    <div className="wrapper_form" >
      <div className="title" style={{ marginTop: ".3%", marginRight: ".4%" }}>Leave Policy</div>
      {lastFile && (
        <List>
          <ListItem className="list-item">
            {lastFile.LVE_FILE_PATH && (
              <IconButton
                onClick={() => openFile(lastFile.LVE_FILE_PATH)}
                className="icon-button"
              >
                <InsertDriveFile /> {/* Original file icon */}
              </IconButton>
            )}
            <ListItemText
              primary={
                lastFile.LVE_FILE_PATH ? (
                  <span
                    className="link-text"
                    onClick={() => openFile(lastFile.LVE_FILE_PATH)} // Open file if path exists
                  >
                    Click here to see <b>Leave Policy</b>
                  </span>
                ) : (
                  <span className="fallback-text">
                    <span className="actual-notice">{lastFile.LVE_ACTUL_NTC}</span> {/* Actual notice text */}
                  </span>
                )
              }
            />
          </ListItem>
        </List>
      )}
    </div>
  );
}
