import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
} from "@mui/material";
import moment from 'moment';

export default function Manager_Report_Team_Mem_Lists() {
    const [leavecnt, Setleavecnt] = useState({
        login_lve_bal_cnt: [], lve_apl_dt: [], login_team_membr_inf_res: [],
        shw_upcom_hlod_res: [], empwise_lve_bal_res: [], all_usr_cnt_data: [], aflt_annocement_news_result: []
    });

    useEffect(() => {
        const lev_bal_cnt = async () => {
            try {
                const lve_cnt = await services.get_lev_bal_cnt();

                if (lve_cnt) {
                    Setleavecnt(lve_cnt);
                } else {
                    console.error("Unexpected data format:", lve_cnt);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        lev_bal_cnt();
    }, []);

    function formatDate(dateString) {
        return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
    }

    console.log("leavecnt", leavecnt);

    // Create a unique list of employees based on USR_EMP_ID
    const uniqueEmployees = Array.from(new Map(leavecnt.empwise_lve_bal_res.map(item => [item.USR_EMP_ID, item])).values());

    return (
        <div className='wrapper_form'>
            <Grid item xs={12} className="grid-item">
                <div className="title">Team Members Details</div>
                <TableContainer component={Paper} className="table-container-leave-type">
                    <Table className="table">
                        <TableHead>
                            <TableRow className="table-row">
                                <TableCell className="header-cell">Sr.No.</TableCell>
                                <TableCell className="header-cell">Name</TableCell>
                                <TableCell className="header-cell">Emp ID</TableCell>
                                <TableCell className="header-cell">Post</TableCell>
                                <TableCell className="header-cell">Joining Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {uniqueEmployees.map((employee, index) => (
                                <TableRow key={employee.USR_EMP_ID}>
                                    <TableCell align="center" className="cell">{index + 1}</TableCell>
                                    <TableCell align="center" className="cell">{employee.USR_DISPLAY_NAME}</TableCell>
                                    <TableCell align="center" className="cell">{employee.USR_EMP_ID}</TableCell>
                                    <TableCell align="center" className="cell">{employee.USR_POST}</TableCell>
                                    <TableCell align="center" className="cell">{formatDate(employee.USR_JOD)}</TableCell>
                                </TableRow>
                            ))}
                            {uniqueEmployees.length < 3 &&
                                Array.from({ length: 3 - uniqueEmployees.length }).map((_, index) => (
                                    <TableRow className="empty-row" key={`empty-row-${index}`}>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                        <TableCell align="center" className="cell">&nbsp;</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}